// imports
import { toast } from "react-toastify";
import React, {
	useEffect,
	useContext,
	useState,
	useCallback,
	useRef,
	lazy,
	Suspense,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

// constants
import {
	ADMIN_MAIN_ROLE,
	ARCHIVE_ACCOUNT,
	BANNED_USER,
	DEACTIVATED_ACCOUNT,
	LEADER_MAIN_ROLE,
	MITTEN_MAIN_ROLE,
	MODERATOR_MAIN_ROLE,
	MUTED_USER,
	PARTNER_MAIN_ROLE,
} from "../../../constants";

// react-bootstrap components used
import {
	Container,
	Spinner,
	Jumbotron,
	Modal,
	OverlayTrigger,
	Tooltip,
	Button,
} from "react-bootstrap";

// user context import
import { UserContext } from "../../../App";

// JSX imports
import UserWidget from "./UserWidget";

// page styles
import "./style.scss";

import {
	getProfile,
	// comments imports
	getProfileComment,
	getProfileCommentsPage,
	createProfileComment,
	editProfileComment,
	reactProfileComment,
	deleteProfileComment,
	obliterateProfileComment,
	// thread imports
	getThreadReplyPage,
	subscribeToThread,
	createProfileCommentReply,
	editProfileCommentReply,
	deleteProfileReply,
	obliterateProfileReply,
} from "../../../api";
import Pagination from "../../Pagination/Pagination";
import removeExcessiveBreaks from "../../../functions/removeExcessiveBreaks";
import DisplayButtons from "../../Insertions/DisplayButtons";
import {
	handleReactComment,
	switchEditState,
} from "../../../functions/commentHelpers";
import StatsOverlay from "./StatsOverlay";
import MainProfile from "./MainProfile";
import ReadMore from "../../Insertions/ReadMore";

const ActionsPane = lazy(() => import("./ActionsPane"));

const Profile = (props) => {
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [user, setUser] = useState<any>(null);
	const [userChanged, setUserChanged] = useState<any>(false);
	const [userMessage, setUserMessage] = useState<string>("");
	const [isDoingQuery, setIsDoingQuery] = useState<boolean>(false);
	const [notifReferer, setNotifReferer] = useState<string | null>(null);

	const [hasLoadedMusic, setHasLoadedMusic] = useState<boolean>(false);

	const [showDisplayRoles, setShowDisplayRoles] = useState<boolean>(false);

	const [bannerImage, setBannerImage] = useState<string>("");
	const [bannerHovered, setBannerHovered] = useState<boolean>(false);

	const [levels, setLevels] = useState<number | undefined>(undefined);
	const [levelCommentCount, setLevelCommentCount] = useState<number>(0);
	const [levelMessage, setLevelMessage] = useState<string>("");

	const [mainProfileData, setMainProfileData] = useState<any>({});
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);

	// change the visibility of the commentbox
	const userIsAdmin = currentUserData?.mainRoles?.includes(ADMIN_MAIN_ROLE);
	const [cantPostComments, setCantPostComments] = useState<boolean>(false);

	// staff check
	const userIsStaff = [
		MITTEN_MAIN_ROLE,
		MODERATOR_MAIN_ROLE,
		PARTNER_MAIN_ROLE,
		ADMIN_MAIN_ROLE,
	].some((role) => currentUserData?.mainRoles?.includes(role));

	// theme class loaders
	const bodyElement = document.querySelector("body");
	const bodyTheme: DOMTokenList | null = bodyElement
		? bodyElement.classList
		: null;
	const themeColorMeta = document.getElementById("theme-color-meta");

	// define history and pagination
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	// hook initializations
	const history = useHistory();
	const query = useQuery();

	// all required pagination
	const page =
		parseInt(query.get("page") ?? "0") > 0
			? parseInt(query.get("page") ?? "0")
			: 1;
	const threadpage =
		parseInt(query.get("threadpage") ?? "0") > 0
			? parseInt(query.get("threadpage") ?? "0")
			: 1;
	const friendspage =
		parseInt(query.get("friendspage") ?? "0") > 1
			? parseInt(query.get("friendspage") ?? "0")
			: 0;
	const inreqpage =
		parseInt(query.get("inreqpage") ?? "0") > 1
			? parseInt(query.get("inreqpage") ?? "0")
			: 0;
	const outreqpage =
		parseInt(query.get("outreqpage") ?? "0") > 1
			? parseInt(query.get("outreqpage") ?? "0")
			: 0;
	const blockpage =
		parseInt(query.get("blockpage") ?? "0") > 1
			? parseInt(query.get("blockpage") ?? "0")
			: 0;
	const ratepage = parseInt(query.get("ratepage") ?? "1") || 1;
	const reviewpage = parseInt(query.get("reviewpage") ?? "1") || 1;
	const favepage = parseInt(query.get("favepage") ?? "1") || 1;
	const referer: string | null = query.get("referer") || null;

	// set a state for notif referer
	useEffect(() => {
		if (referer && !notifReferer) setNotifReferer(referer);
	}, [referer, notifReferer]);

	// cleanup - component unload
	useEffect(() => {
		return () => {
			setUserChanged(null);
			setUser(null);
			setHasLoadedMusic(false);
		};
	}, []);

	// handle window load
	useEffect(() => {
		if (user?._id !== props.match.params.id) {
			history.push(window.location.pathname);
			setThemeRendered(false);
			setUser(null);
			setHasLoadedMusic(false);
			setUserChanged(true);
		}
	}, [user?._id, props.match.params.id, history]);

	// changes the theme to the user's profile theme once the user loads
	const themeColorSwitcher = useCallback(
		(user) => {
			const profileThemes: string[] = user?.profile_themes || [
				"squarehue",
				"default",
			];
			let themeColor = "#a6a692";

			switch (profileThemes?.[0]) {
				case "squarehue":
					themeColor = "#10127c";
					break;
				case "memoryfibre":
					themeColor = "#7116cd";
					break;
				case "inkletgloss":
					themeColor = "#00113e";
					break;
				case "gloamvitiation":
					themeColor = "#cf4d02";
					break;
				case "butterflybrew":
					themeColor = "#8a23ba";
					break;
				case "wildheart":
					themeColor = "#01522c";
					break;
				case "hydrawisp":
					themeColor = "#3d0101";
					break;
				case "riftshade":
					themeColor = "#171717";
					break;
				case "moonash":
					themeColor = "#a6a692";
					break;
				case "sugarblur":
					themeColor = "#fc83c1";
					break;
				default:
					themeColor = "#10127c";
			}

			if (user?._id && bodyTheme && profileThemes) {
				// create a loop for the bodytheme length
				for (
					let i = bodyTheme !== null ? bodyTheme?.length - 1 : 0;
					i >= 0;
					i--
				) {
					// remove any themes that arent present
					if (
						bodyTheme[i] !== profileThemes[0] &&
						bodyTheme[i] !== profileThemes[1]
					) {
						bodyTheme?.remove(bodyTheme[i]);
					} else if (i < 0) break;
				}
				// apply the theme
				bodyTheme?.add(profileThemes[1]);
				bodyTheme?.add(profileThemes[0]);
				// adjust theme colour for mobile/web-app
				if (themeColorMeta)
					themeColorMeta.setAttribute("content", themeColor);
			}
			return;
		},
		[bodyTheme, themeColorMeta]
	);

	// retrieve user info with ID from props
	useEffect(() => {
		if (props.match.params.id === user?._id) return setUserChanged(false);

		if (userChanged && !isDoingQuery) {
			setIsDoingQuery(true);
			setUserChanged(false);
			// if this is not the case... make a backend request
			getProfile(props.match.params.id)
				.then((response) => {
					// use the response to update every state needed
					setUser(response?.data?.user);
					setUserMessage(
						response?.status === 204
							? "No user found!"
							: response?.data?.message
					);
					if (response?.data !== undefined && response?.data !== "") {
						setLevels(parseInt(response?.data?.levels) || 0);
						setLevelCommentCount(response?.data?.comments);
						setLevelMessage(response?.data?.message);
						setMainProfileData(response?.data?.main);
						setCantPostComments(
							(!userIsAdmin &&
								response.data.user?.mainRoles?.some((role) =>
									[
										ARCHIVE_ACCOUNT,
										BANNED_USER,
										DEACTIVATED_ACCOUNT,
									].includes(role)
								)) ||
								(!userIsStaff &&
									(currentUserData?.mainRoles?.includes(
										MUTED_USER
									) ||
										!currentUserData ||
										currentUserData?.verified === false))
						);
						setHasDoneQuery(true);
						setTimeout(() => {
							setShowDisplayRoles(true);
						}, 550);
					}
					// set the user banner and check if it loads in properly
					const banner = new Image();
					banner.src = response?.data?.user?.banner || "";
					banner.onload = () => {
						setBannerImage(banner.src);
					};
					banner.onerror = () => {
						setBannerImage("");
					};

					document.title = `${
						response?.data?.user?.username
							? response.data.user?.username
							: "but nobody came"
					} - Level Share Square`;

					return setIsDoingQuery(false);
				})
				.catch(() => setIsDoingQuery(false));
		}
		return () => setThemeRendered(false);
	}, [
		userChanged,
		props.match.params.id,
		userIsAdmin,
		userIsStaff,
		currentUserData,
		user?._id,
		isDoingQuery,
	]);

	const [themeRendered, setThemeRendered] = useState<boolean>(false);
	useEffect(() => {
		if (!themeRendered && user?._id) {
			setThemeRendered(true);
			themeColorSwitcher(user);
		}
	}, [themeRendered, user, themeColorSwitcher]);

	const videoId = props.videoId;
	const setVideoId = props.setVideoId;

	useEffect(() => {
		if (!hasLoadedMusic && user?._id) {
			setHasLoadedMusic(true);
			if (
				currentUserData?._id === user?._id ||
				currentUserData?.general_settings?.[1] === "on"
			) {
				const music = user?.music_ids?.[1] || "";
				if (music !== "" && videoId !== music) return setVideoId(music);
				const musicUrl =
					currentUserData?.music_ids?.[0] || "ju1NDfacnQ8";
				// switch music back to default when leaving profiles
				if (videoId !== musicUrl) return setVideoId(musicUrl);
				return;
			} else {
				const musicUrl =
					currentUserData?.music_ids?.[0] || "ju1NDfacnQ8";
				// switch music back to default when leaving profiles
				if (videoId !== musicUrl) return setVideoId(musicUrl);
				return;
			}
		}
	}, [
		hasLoadedMusic,
		user?._id,
		videoId,
		currentUserData?._id,
		currentUserData?.general_settings,
		currentUserData?.music_ids,
		setVideoId,
		user?.music_ids,
	]);

	// shared props for actionspane
	const actionsPaneProps = {
		setInfractionModalShow: props.setInfractionModalShow,
		userIsAdmin: userIsAdmin,
		user: user,
		setUser: setUser,
		levels: levels,
		setShowDisplayRoles: setShowDisplayRoles,
		friendspage: friendspage,
		inreqpage: inreqpage,
		outreqpage: outreqpage,
		blockpage: blockpage,
		favepage: favepage,
		history: history,
		query: query,
		bannerHovered: bannerHovered,
		setCurrentUserData: props.setCurrentUserData,
	};

	// render
	return userChanged || !user?._id ? (
		// loading spinner
		<div>
			{!userMessage && (
				<div
					className="d-flex justify-content-center"
					id="loading-spinner"
					style={{ margin: "35vh 0 35vh 0" }}>
					<Spinner animation="grow" variant="primary" />
				</div>
			)}

			{/* display "no user found" message if none is found */}
			{userMessage && !user && (
				<div className="container mt-4">
					{["null", "success"].includes(userMessage) ? (
						<div
							className="d-flex justify-content-center"
							style={{ margin: "35vh 0 35vh 0" }}>
							<Spinner animation="grow" variant="primary" />
						</div>
					) : (
						<div className="col-12">
							<div
								className="card"
								id="infoCard"
								style={{ textAlign: "center" }}>
								<div className="card-body" id="infoCard">
									<h2 className="card-title">
										{userMessage}
									</h2>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	) : (
		// otherwise, print user profile information
		<Container className="profile">
			<div
				className="profile__upper"
				style={{
					backgroundImage: `url(${bannerImage})`,
				}}>
				<div
					className="profile__upper__banner unselectable"
					onMouseOver={() => !bannerHovered && setBannerHovered(true)}
					onMouseLeave={() => setBannerHovered(false)}>
					<UserWidget
						user={user}
						levels={levels}
						showDisplayRoles={showDisplayRoles}
						images={props.images}
					/>
					{currentUserData?._id ? (
						<span className="d-none d-lg-block">
							<Suspense
								fallback={
									<Spinner
										animation="grow"
										variant="primary"
										style={{ marginTop: "10px" }}
									/>
								}>
								<ActionsPane
									{...actionsPaneProps}
									device="desktop"
								/>
							</Suspense>
						</span>
					) : null}
				</div>
				<StatsOverlay
					currentUserData={currentUserData}
					user={user}
					setUser={setUser}
					levels={levels}
					levelMessage={levelMessage}
					ratepage={ratepage}
					reviewpage={reviewpage}
					levelCommentCount={levelCommentCount}
					showDisplayRoles={showDisplayRoles}
					gameProperties={props.gameProperties}
				/>
			</div>

			<Jumbotron className="d-lg-none">
				{currentUserData?._id ? (
					<Suspense
						fallback={
							<Spinner
								animation="grow"
								variant="primary"
								style={{ marginTop: "10px" }}
							/>
						}>
						<ActionsPane {...actionsPaneProps} device="mobile" />
					</Suspense>
				) : null}
			</Jumbotron>

			{hasDoneQuery && user?._id ? (
				<Jumbotron className="profile__middle">
					<MainProfile
						user={user}
						mainProfileData={mainProfileData}
					/>
				</Jumbotron>
			) : null}

			<Jumbotron className="profile__bottom">
				<Comments
					user={user}
					levels={levels}
					images={props.images}
					userIsAdmin={userIsAdmin}
					userIsStaff={userIsStaff}
					cantPostComments={cantPostComments}
					page={page}
					threadpage={threadpage}
					history={history}
					query={query}
					setUserChanged={setUserChanged}
					notifReferer={notifReferer}
					setNotifReferer={setNotifReferer}
				/>
			</Jumbotron>
		</Container>
	);
};

const Comments = (props) => {
	const userData = props.user;

	const [profileComments, setProfileComments] = useState<any[]>([]);
	const [controlProfileComments, setControlProfileComments] = useState<any[]>(
		[]
	);
	const [previousPage, setPreviousPage] = useState<any>(null);
	const [profileCommentsChanged, setProfileCommentsChanged] =
		useState<boolean>(true);
	const [authors, setAuthors] = useState<any[]>([]);
	const [isQuerying, setIsQuerying] = useState<boolean>(false);

	const [lockCommentSubmit, setLockCommentSubmit] = useState<boolean>(false);
	const [commentValue, setCommentValue] = useState<string>(
		sessionStorage.getItem(`profilecomment${userData?._id}`) || ""
	);
	const [numberOfPages, setNumberOfPages] = useState<number>(0);
	const [targetProfileComment, setTargetProfileComment] = useState<any>("");
	const commentRef = useRef<any>(null);
	const [stickyButton, setStickyButton] = useState<boolean>(false);

	const [referedProfileComment, setReferedProfileComment] =
		useState<any>(null);

	const currentUserData = useContext<any>(UserContext);
	const isWritingComment =
		window.innerWidth < 1000
			? commentValue.length > 50
			: commentValue.length > 120;
	const history = props.history;
	const notifReferer = props.notifReferer;
	const setNotifReferer = props.setNotifReferer;

	// track scroll behaviour
	const handleScroll = () => {
		if (commentRef.current) {
			// get position
			const refBottom =
				commentRef?.current?.getBoundingClientRect()?.top +
				window.scrollY -
				500;
			// enable/disable the button
			if (refBottom >= window.scrollY) {
				setStickyButton(true);
			} else {
				setStickyButton(false);
			}
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	// set a referer for a profile comment modal
	useEffect(() => {
		if (notifReferer) {
			history.push(window.location.pathname);
			getProfileComment(notifReferer).then((response) => {
				// remove the notifrefer, store the comment as ref and target
				setNotifReferer(null);
				setTargetProfileComment(response?.data?.comment);
				setReferedProfileComment(response?.data?.comment);
				//  append the new author if applicable
				setAuthors((prevAuthors) => {
					// variables & checks
					let newAuthors: any[] = [];
					const authorResponse = response?.data?.author;
					// add currentuserdata to the array if it exists
					if (currentUserData?._id) newAuthors.push(currentUserData);
					// add the previous authors
					if (prevAuthors?.length)
						newAuthors = newAuthors.concat(prevAuthors);
					// add the authors from the response variable
					if (authorResponse) newAuthors.push(authorResponse);
					// Filter out duplicate authors based on _id
					newAuthors = newAuthors.filter(
						(author, index, self) =>
							index ===
							self.findIndex((a) => a._id === author._id)
					);
					// return newAuthors as the new value
					return newAuthors;
				});
			});
		}
	}, [notifReferer, setNotifReferer, currentUserData, history]);

	// retrieve profile comments
	useEffect(() => {
		if (parseInt(props.query.get("page")) === previousPage || isQuerying)
			return setProfileCommentsChanged(false);

		if (profileCommentsChanged && userData?._id) {
			const id = userData._id;
			setProfileCommentsChanged(false);
			setIsQuerying(true);
			getProfileCommentsPage(id, { page: props.page })
				.then((response) => {
					if (response?.data) {
						setPreviousPage(props.page);
						setProfileComments(response?.data?.profileComments);
						setAuthors((prevAuthors) => {
							// variables & checks
							let newAuthors: any[] = [];
							const authorResponse = response?.data?.authors;
							const currentUserExists = currentUserData?._id;

							// add currentuserdata to the array if it exists
							if (currentUserExists)
								newAuthors.push(currentUserData);

							// add the previous authors
							if (prevAuthors?.length)
								newAuthors = newAuthors.concat(prevAuthors);

							// add the authors from the response variable
							if (authorResponse?.length)
								newAuthors = newAuthors.concat(authorResponse);

							// Filter out duplicate authors based on _id
							newAuthors = newAuthors.filter(
								(author, index, self) =>
									index ===
									self.findIndex((a) => a._id === author._id)
							);
							// return newAuthors as the new value
							return newAuthors;
						});

						setControlProfileComments(
							response?.data?.profileComments
						);
						setNumberOfPages(response.data.numberOfPages || 1);
					}
				})
				.finally(() => setIsQuerying(false));
		}
	}, [
		profileCommentsChanged,
		props.page,
		currentUserData?._id,
		userData._id,
		previousPage,
		currentUserData,
		props.query,
		isQuerying,
	]);

	// cleanup hook
	useEffect(() => {
		return () => {
			setProfileCommentsChanged(false);
			setProfileComments([]);
		};
	}, []);

	// set comment as private or public
	const handleCommentSubmit = (e) => {
		e.preventDefault();

		// abort if the user is muted
		if (currentUserData && currentUserData.mainRoles?.includes(MUTED_USER))
			return toast.error("You cannot perform this action while muted!");

		if (currentUserData?.blocked_users?.includes(userData?._id))
			return toast.error("You have blocked this user.");

		if (userData?.blocked_users?.includes(currentUserData?._id))
			return toast.error("This user has blocked you!");

		// submit level comment if user has actually entered one
		if (commentValue) {
			setLockCommentSubmit(true);
			const buttonName = e.nativeEvent.submitter.name;
			const id = userData._id;
			toast.info("Adding comment...");

			createProfileComment(id, {
				content: removeExcessiveBreaks(commentValue),
				buttonName,
			})
				.then((response) => {
					if (response?.data) {
						// add the new profilecomment
						setProfileComments((prevState: any[]) => {
							const newProfileComments = [
								response.data.newProfileComment,
								...prevState,
							];
							return newProfileComments;
						});
						// add current user to authors array
						setCommentValue("");
						sessionStorage.removeItem(
							`profilecomment${userData?._id}`
						);
					}
					setLockCommentSubmit(false);
				})
				.catch(() => setLockCommentSubmit(false));
		} else toast.error("You can't post an empty comment!");
	};

	return (
		<div className="comments">
			<div
				style={{
					position: "fixed",
					bottom: "16px",
					right: "16px",
					zIndex: "99",
					opacity: stickyButton ? "1" : "0",
					transition: "opacity 0.8s ease-in-out",
					// @ts-ignore
					Index: "100",
				}}>
				<button
					style={{
						borderStyle: "solid",
						borderWidth: "2px",
						borderColor: "white",
						color: "white",
						borderRadius: "4px",
						padding: "4px",
						backgroundColor: "#ffffff70",
						zIndex: "100",
					}}
					onClick={() => {
						const topRef = // @ts-ignore
							commentRef.current.getBoundingClientRect().top;
						window.scrollTo({
							top: topRef + window.scrollY - 100,
							behavior: "smooth",
						});
					}}>
					Comments ▼
				</button>
			</div>
			<h2 className="comments__header" ref={commentRef}>
				Comments
			</h2>
			<div className="comments__comment-wrapper">
				{!props.cantPostComments && (
					<form onSubmit={(e) => handleCommentSubmit(e)}>
						<div className="form-group">
							<textarea
								className="form-control"
								style={{
									resize: "none",
									marginLeft: "unset",
									width: "100%",
									height: isWritingComment ? "180px" : "72px",
									marginBottom: "8px",
									transition: "height 0.85s ease-in-out",
								}}
								value={commentValue}
								onChange={(e) => {
									const value = e.target.value;
									setCommentValue(value);
									if (value)
										return sessionStorage.setItem(
											`profilecomment${userData?._id}`,
											value
										);
									sessionStorage.removeItem(
										`profilecomment${userData?._id}`
									);
								}}
								id="submit_comment"
								name="content"
								maxLength={1250}
								rows={7}
								placeholder={
									currentUserData?._id === userData?._id
										? "Fancy a status?"
										: userData?.mainRoles?.includes(
												LEADER_MAIN_ROLE
										  )
										? "No suggestions, or else a cheetah will attack you and say 'No suggestions'!"
										: userData?.flairs ||
										  "Feel like having a chat?"
								}></textarea>
							<div
								style={{
									margin: "0 auto",
									width: "max-content",
								}}>
								<button
									disabled={
										lockCommentSubmit ||
										(!props.userIsStaff &&
											currentUserData &&
											currentUserData.mainRoles?.includes(
												MUTED_USER
											)) ||
										commentValue === ""
									}
									type="submit"
									className="btn btn-primary"
									name="public">
									{currentUserData?._id === userData?._id
										? "Post status update"
										: "Send"}
									{commentValue?.length ? (
										<span>
											&nbsp;({commentValue?.length})
										</span>
									) : null}
								</button>
								{currentUserData?._id !== userData?._id ? (
									<>
										&nbsp;&nbsp;
										<button
											disabled={
												lockCommentSubmit ||
												(!currentUserData?.isStaff &&
													currentUserData &&
													// muted users cant comment
													currentUserData.mainRoles?.includes(
														MUTED_USER
													)) ||
												//? social settings
												// friends only
												(userData?.social?.[1] ===
													"1" &&
													// check friend
													!userData?.friends?.includes(
														currentUserData?._id
													) &&
													// check staff
													!currentUserData?.isStaff) ||
												// disabled
												(userData?.social?.[1] ===
													"2" &&
													!currentUserData?.isStaff) ||
												commentValue === ""
											}
											type="submit"
											className="btn btn-primary__special"
											name="private">
											{userData?.social?.[1] === "1" &&
											// check friend
											!userData?.friends?.includes(
												currentUserData?._id
											) &&
											// check staff
											!currentUserData?.isStaff
												? "Only friends can privately comment"
												: userData?.social?.[1] ===
														"2" &&
												  !currentUserData?.isStaff
												? "This user has private comments disabled"
												: "Send privately"}
										</button>
									</>
								) : null}
							</div>
						</div>
					</form>
				)}
				<Comment
					profileComments={profileComments}
					controlProfileComments={controlProfileComments}
					authors={authors}
					images={props.images}
					userIsStaff={props.userIsStaff}
					userIsAdmin={props.userIsAdmin}
					userData={userData}
					setProfileCommentsChanged={setProfileCommentsChanged}
					page={props.page}
					threadpage={props.threadpage}
					targetProfileComment={targetProfileComment}
					setTargetProfileComment={setTargetProfileComment}
					setProfileComments={setProfileComments}
					setControlProfileComments={setControlProfileComments}
					cantPostComments={props.cantPostComments}
					// @ts-ignore
					history={history}
					query={props.query}
					setUserChanged={props.setUserChanged}
					referedProfileComment={referedProfileComment}
					setReferedProfileComment={setReferedProfileComment}
				/>

				{profileComments.length !== 0 ? (
					<div className="MuiPagination-root">
						<Pagination
							setContentChanged={setProfileCommentsChanged}
							page={props.page}
							numberOfPages={numberOfPages}
							type="comment"
							siblingCount={2}
						/>
					</div>
				) : (
					<div
						className="comment public-comment"
						style={{ marginTop: "12px" }}>
						<div
							className="comment__comment-body"
							style={{ textAlign: "center", marginTop: "46px" }}>
							{previousPage === null
								? "Fetching comments..."
								: currentUserData?.verified === true
								? "Start the conversation!"
								: currentUserData?.verified === false
								? "Unverified users can't comment"
								: "Log in to send the first comment!"}
						</div>
					</div>
				)}
				<br />
			</div>
		</div>
	);
};

const Comment = ({
	profileComments,
	controlProfileComments,
	authors,
	images,
	userIsStaff,
	userIsAdmin,
	userData,
	setProfileCommentsChanged,
	page,
	threadpage,
	targetProfileComment,
	setTargetProfileComment,
	setProfileComments,
	setControlProfileComments,
	cantPostComments,
	setUserChanged,
	referedProfileComment,
	setReferedProfileComment,
	query,
}) => {
	const currentUserData = useContext<any>(UserContext);

	// state selectors
	const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

	const [commentThreadModalShow, setCommentThreadModalShow] =
		useState<boolean>(false);
	const [commentReply, setCommentReply] = useState<string>("");
	const [lockReplySubmit, setLockReplySubmit] = useState<boolean>(false);

	const [repliesThreadChanged, setRepliesThreadChanged] =
		useState<boolean>(false);
	const [threadReplies, setThreadReplies] = useState<any[]>([]);
	const [controlThreadReplies, setControlThreadReplies] = useState<any>([]);
	const [numberOfThreadPages, setNumberOfThreadPages] = useState<number>(0);
	const [replyAuthors, setReplyAuthors] = useState<any[]>([]);

	const [replyParentComment, setReplyParentComment] = useState<any>("");
	const [showReplies, setShowReplies] = useState<boolean>(false);

	const [profileCommentAction, setProfileCommentAction] = useState<any>("");
	const history = useHistory();

	// fetch replies belonging to a comment if "targetprofilecomment" gets triggered
	useEffect(() => {
		if (targetProfileComment !== "" && repliesThreadChanged) {
			const id = userData?._id;
			const commentID = targetProfileComment?._id;
			setShowReplies(false);
			getThreadReplyPage(id, commentID, { page, threadpage }).then(
				(response) => {
					if (response?.data) {
						if (
							response?.data?.numberOfPages < threadpage &&
							response?.data?.numberOfPages !== 0
						) {
							query.set("threadpage", "1");
							history.push(`?${query.toString()}`);
							return setRepliesThreadChanged(true);
						}
						// set the authors, threads and a control object
						setThreadReplies(response.data.profileCommentReplies);
						setReplyAuthors(
							currentUserData?._id &&
								response?.data?.authors?.length
								? [...response?.data?.authors, currentUserData]
								: currentUserData?._id
								? [currentUserData]
								: response?.data?.authors
						);
						setControlThreadReplies(
							response.data.profileCommentReplies
						);
						setNumberOfThreadPages(
							response.data.numberOfPages || 1
						);
						// render replies if true
						if (response.data.profileCommentReplies.length !== 0)
							setShowReplies(true);
					}
				}
			);

			return setRepliesThreadChanged(false);
		}
	}, [
		targetProfileComment,
		commentThreadModalShow,
		repliesThreadChanged,
		threadpage,
		page,
		userData?._id,
		showReplies,
		currentUserData,
		query,
		history,
	]);

	// handle the deletion or obliteration of a comment - displays modal.
	const handleProfileCommentDeleteOrObliterate = (e, action, document) => {
		e.preventDefault();
		// Set comment visibility to deleted
		if (action === "deleteComment") {
			setProfileCommentAction("delete-C");
			setTargetProfileComment(document);
			return setDeleteModalShow(true);
		}
		// Comment is being OBLITERATED from the database
		if (action === "obliterateComment") {
			setProfileCommentAction("obliterate-C");
			setTargetProfileComment(document);
			return setDeleteModalShow(true);
			// Set reply visibility to deleted
		}
		if (action === "deleteReply") {
			setProfileCommentAction("delete-R");
			setTargetProfileComment(document);
			setReplyParentComment(targetProfileComment?._id);
			return setDeleteModalShow(true);
		}
		// Reply is being OBLITERATED from the database
		if (action === "obliterateReply") {
			setProfileCommentAction("obliterate-R");
			setTargetProfileComment(document);
			setReplyParentComment(targetProfileComment?._id);
			return setDeleteModalShow(true);
		}
		return toast.error("Invalid method.");
	};

	// display modal for deleting profile comment
	const deleteProfileCommentModal = () => {
		return (
			<Modal
				show={deleteModalShow}
				onHide={() => setDeleteModalShow(false)}
				size="sm"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span className="modal-fill">
					<Modal.Body>
						<h4 style={{ textAlign: "center" }}>
							{profileCommentAction === "delete-C"
								? "Are you sure you want to delete this comment?"
								: profileCommentAction === "obliterate-C"
								? "Are you sure you want to OBLITERATE this comment from the database?"
								: profileCommentAction === "delete-R"
								? "Are you sure you want to delete this reply?"
								: profileCommentAction === "obliterate-R"
								? "Are you sure you want to OBLITERATE this reply from the database?"
								: "INVALID ACTION"}
						</h4>
					</Modal.Body>
					<Modal.Footer className="mx-auto modal-buttons">
						<Button
							onClick={() => {
								// profile ID
								const profileID = userData._id;
								//! profile comment or profile reply
								const profileCommentID =
									targetProfileComment._id;
								const resetValues = () => {
									setTargetProfileComment("");
									setDeleteModalShow(false);
								};

								// make backend call for deleting a level.
								//* comment
								if (profileCommentAction === "delete-C") {
									toast.info("deleting comment...");
									return deleteProfileComment(
										profileCommentID,
										{
											profileID,
										}
									).then(() => {
										resetValues();
										setProfileComments((prevState) => {
											// Find the index of the profileComment with matching profileCommentID
											const commentIndex =
												prevState.findIndex(
													(comment) =>
														comment._id ===
														profileCommentID
												);

											if (commentIndex !== -1) {
												// Create a copy of the profileComments array
												const updatedProfileComments = [
													...prevState,
												];
												// Update the visibility of the profileComment to "deleted"
												updatedProfileComments[
													commentIndex
												] = {
													...updatedProfileComments[
														commentIndex
													],
													visibility: "deleted",
												};

												// Set the state with the updated profileComments array
												return updatedProfileComments;
											}

											// Return prevState if the profileCommentID was not found (optional)
											return prevState;
										});
									});
								}
								// obliterate it instead
								//* comment
								if (profileCommentAction === "obliterate-C") {
									toast.info("obliterating comment...");
									return obliterateProfileComment(
										profileCommentID,
										{
											profileID,
										}
									).then(() => {
										resetValues();
										setProfileComments((prevState) => {
											// Find the index of the profileComment with matching profileCommentID
											const updatedComments =
												prevState.filter(
													(comment) =>
														comment._id !==
														profileCommentID
												);
											// Set the state with the updated profileComments array
											return updatedComments;
										});
									});
								}

								// make backend call for deleting a reply
								//! profileCommentID is a reply
								if (profileCommentAction === "delete-R") {
									toast.info("deleting reply...");
									return deleteProfileReply(
										profileCommentID,
										replyParentComment,
										profileID
									).then(() => {
										resetValues();
										setProfileCommentsChanged(true);
									});
								}
								// obliterate it instead
								//! profileCommentID is a reply
								if (profileCommentAction === "obliterate-R") {
									toast.info("obliterating reply...");
									return obliterateProfileReply(
										profileCommentID,
										replyParentComment,
										profileID
									).then(() => {
										resetValues();
										setProfileCommentsChanged(true);
									});
								}
								return resetValues();
							}}>
							Yes
						</Button>
						<Button onClick={() => setDeleteModalShow(false)}>
							No
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	const incrementReplyCount = (commentId) => {
		setProfileComments((prevComments) => {
			return prevComments.map((comment) => {
				if (comment._id === commentId) {
					let updatedReplyCount;
					if (!comment.replyCount) {
						updatedReplyCount = (comment?.replies?.length || 0) + 1;
					} else {
						updatedReplyCount = (comment.replyCount || 0) + 1;
					}
					return {
						...comment,
						replyCount: updatedReplyCount,
					};
				}
				return comment;
			});
		});
	};

	const commentThreadModal = () => {
		const profileComment =
			referedProfileComment !== null
				? referedProfileComment
				: profileComments.find(
						(comment) => comment?._id === targetProfileComment?._id
				  );
		// author of the comment
		const commentAuthor = authors?.find(
			(user) => user._id === profileComment?.author
		);
		// check for admin
		const authorIsAdmin =
			commentAuthor?.mainRoles?.includes(ADMIN_MAIN_ROLE);

		return (
			<Modal
				show={commentThreadModalShow || referedProfileComment !== null}
				onHide={() => {
					setCommentThreadModalShow(false);
					setReferedProfileComment(null);
				}}
				className="comment-modal"
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<div
					className="modal-fill"
					style={{
						width: "100%",
					}}>
					<Modal.Body className="thread-modal-body">
						<div
							className={`comment ${
								targetProfileComment?.visibility === "private"
									? "private-comment"
									: targetProfileComment?.visibility ===
									  "public"
									? targetProfileComment?.author ===
									  userData._id
										? "status-comment"
										: "public-comment"
									: "deleted-comment"
							}__main`}
							style={{
								backgroundColor: "rgb(255,255,255,0.1)",
							}}>
							<div>
								<div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											outlineColor:
												commentAuthor?.icon_outline,
											boxShadow: commentAuthor?.icon_glow
												? `0 0 6px 3px ${commentAuthor?.icon_glow}`
												: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													images?.defaultAvatar;
											}}
											src={
												commentAuthor?.avatar
													? commentAuthor?.avatar
													: images?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={commentAuthor?.username}
											onClick={(e) => {
												e.preventDefault();
												history.push(
													`/users/${targetProfileComment?.author}`
												);
												window.scrollTo(0, 0);
											}}
											href={`/users/${targetProfileComment?.author}`}>
											{commentAuthor?.username}
										</a>
									</span>
								</div>
								<hr
									style={{
										margin: "2px 7px 14px 7px",
									}}
								/>
								{/* all buttons for primary profile comments */}
								{targetProfileComment?._id ? (
									<DisplayButtons
										elementsToDisplay={[
											"delete",
											"obliterate",
										]}
										comment={targetProfileComment}
										currentUserData={currentUserData}
										setThreadModalShow={
											setCommentThreadModalShow
										}
										setTargetComment={
											setTargetProfileComment
										}
										handleDeletion={
											handleProfileCommentDeleteOrObliterate
										}
										authorIsAdmin={authorIsAdmin}
										type={"Comment"}
										setComments={setProfileComments}
									/>
								) : null}
								{/* display private or deleted text */}
								{targetProfileComment?.visibility ===
								"private" ? (
									<span className="comment__private-display">
										Private
									</span>
								) : targetProfileComment?.visibility ===
								  "deleted" ? (
									<span className="comment__deleted-display">
										Deleted
									</span>
								) : targetProfileComment?.visibility ===
										"public" &&
								  userData?._id ===
										targetProfileComment?.author ? (
									// if the profile author is the commentor, add add a status display for like/dislike
									<CommentStatus
										parentProfileComment={
											targetProfileComment
										}
										profileComments={profileComments}
										userData={userData}
										setProfileComments={setProfileComments}
									/>
								) : (
									""
								)}
								{/* comment + timestamp */}
								<div className="comment__comment-body">
									<ReadMore
										content={targetProfileComment?.content}
										document={targetProfileComment}
									/>
								</div>
								<div className="comment__comment-timestamp">
									Posted on{" "}
									<b>
										{moment(targetProfileComment?.postDate)
											.format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
							</div>
						</div>
						<hr />
						<CommentReplies
							threadReplies={threadReplies}
							setThreadReplies={setThreadReplies}
							controlThreadReplies={controlThreadReplies}
							setControlThreadReplies={setControlThreadReplies}
							userIsStaff={userIsStaff}
							images={images}
							userIsAdmin={userIsAdmin}
							userData={userData}
							replyAuthors={replyAuthors}
							showReplies={showReplies}
							setDeleteModalShow={setDeleteModalShow}
							handleProfileCommentDeleteOrObliterate={
								handleProfileCommentDeleteOrObliterate
							}
							setCommentThreadModalShow={
								setCommentThreadModalShow
							}
							targetProfileComment={targetProfileComment}
							setTargetProfileComment={setTargetProfileComment}
							setUserChanged={setUserChanged}
						/>
						<Pagination
							setContentChanged={setRepliesThreadChanged}
							threadpage={threadpage}
							numberOfThreadPages={numberOfThreadPages}
							showReplies={showReplies}
							type="reply"
						/>
					</Modal.Body>
					{!cantPostComments ||
					targetProfileComment?.author ===
						"696969696969696969696969" ? (
						<Modal.Footer className="mx-auto modal-buttons">
							{" "}
							<span style={{ margin: "0 auto" }}>
								<textarea
									// @ts-ignore
									type="text"
									placeholder="Write a reply to this comment"
									className="form form-control thread-form"
									style={{ width: "90%" }}
									maxLength={2500}
									value={commentReply}
									onChange={(e) =>
										setCommentReply(e.target.value)
									}
								/>
								<br />
								<div className="thread-footer">
									<Button
										className="btn-primary__special"
										style={{
											position: "relative",
											left: "0",
										}}
										disabled={lockReplySubmit}
										// send a reply to a comment in the thread
										onClick={() => {
											const profileID = userData?._id;
											const commentID =
												targetProfileComment?._id;
											if (
												commentReply !== "" &&
												!currentUserData?.mainRoles?.includes(
													MUTED_USER
												)
											) {
												if (
													currentUserData?.blocked_users?.includes(
														profileID
													)
												)
													return toast.error(
														"You cannot reply to comments on profiles of users you've blocked."
													);

												if (
													userData?.blocked_users?.includes(
														currentUserData?._id
													)
												)
													return toast.error(
														"You're blocked by the owner of this profile!"
													);

												if (
													currentUserData?.blocked_users?.includes(
														targetProfileComment?.author
													)
												)
													return toast.error(
														"You cannot reply to users you've blocked."
													);

												if (
													commentAuthor?.blocked_users?.includes(
														currentUserData?._id
													)
												)
													return toast.error(
														"You're blocked by the author of this comment."
													);

												setLockReplySubmit(true);
												createProfileCommentReply(
													profileID,
													commentID,
													{
														content:
															removeExcessiveBreaks(
																commentReply
															),
													}
												)
													.then((response) => {
														if (response?.data) {
															// update thread
															setThreadReplies(
																(
																	threadReplies
																) => [
																	...threadReplies,
																	response
																		.data
																		.newProfileCommentReply,
																]
															);
															// update the profileComment's subscribers
															if (
																!profileComment.subscribers.includes(
																	currentUserData._id
																)
															) {
																setProfileComments(
																	(
																		prevComments
																	) =>
																		prevComments.map(
																			(
																				prevComment
																			) => {
																				// select the specific annoucnement
																				if (
																					profileComment._id ===
																					prevComment._id
																				) {
																					return {
																						...prevComment,
																						subscribers:
																							[
																								...prevComment?.subscribers,
																								currentUserData._id,
																							],
																					};
																				} else
																					return prevComment;
																			}
																		)
																);
															}
															setLockReplySubmit(
																false
															);
															setCommentReply("");
															setShowReplies(
																true
															);
															incrementReplyCount(
																profileComment._id
															);
														}
													})
													.catch(() =>
														setLockReplySubmit(
															false
														)
													);
											} else {
												if (
													userData?.social?.[1] ===
													"2"
												) {
													toast.error(
														"This user does not allow replies"
													);
												}

												currentUserData?.mainRoles?.includes(
													MUTED_USER
												)
													? toast.error(
															"You can't post comments while muted!"
													  )
													: toast.error(
															"New comments can't be empty!"
													  );
											}
										}}>
										Send{" "}
										{commentReply?.length
											? `(${commentReply?.length})`
											: null}
									</Button>{" "}
									<Button
										disabled={lockReplySubmit}
										onClick={() => {
											const type = "profileComment";
											const force =
												profileComment?.subscribers?.includes(
													currentUserData?._id
												)
													? "unsubscribe"
													: "subscribe";
											setLockReplySubmit(true);
											// backend request
											subscribeToThread(
												profileComment?._id,
												force,
												type
											)
												.then(() => {
													setLockReplySubmit(false);
													setProfileComments(
														(prevComments) =>
															prevComments.map(
																(
																	prevComment
																) => {
																	if (
																		profileComment._id ===
																		prevComment._id
																	) {
																		// Add or remove currentUserData._id based on the value of "force"
																		const updatedSubscribers =
																			force ===
																			"subscribe"
																				? [
																						...(prevComment?.subscribers ||
																							[]),
																						currentUserData._id,
																				  ] // Add to followers array
																				: prevComment?.subscribers?.filter(
																						(
																							id
																						) =>
																							id !==
																							currentUserData._id
																				  ); // Remove from followers array
																		return {
																			...(prevComment ||
																				[]),
																			subscribers:
																				updatedSubscribers,
																		};
																	} else {
																		return prevComment;
																	}
																}
															)
													);
												})
												.catch(() =>
													setLockReplySubmit(false)
												);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										{profileComment?.subscribers?.includes(
											currentUserData?._id
										)
											? "Unsubscribe"
											: "Subscribe"}
									</Button>{" "}
									<Button
										onClick={() => {
											setCommentThreadModalShow(false);
											setReferedProfileComment(null);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										Close
									</Button>
								</div>
							</span>
						</Modal.Footer>
					) : (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								{currentUserData?._id && (
									<>
										<Button
											disabled={lockReplySubmit}
											onClick={() => {
												const type = "profileComment";
												const force =
													profileComment?.subscribers?.includes(
														currentUserData?._id
													)
														? "unsubscribe"
														: "subscribe";
												setLockReplySubmit(true);
												// backend request
												subscribeToThread(
													profileComment?._id,
													force,
													type
												)
													.then(() => {
														setLockReplySubmit(
															false
														);
														setProfileComments(
															(prevComments) =>
																prevComments.map(
																	(
																		prevComment
																	) => {
																		if (
																			profileComment._id ===
																			prevComment._id
																		) {
																			// Add or remove currentUserData._id based on the value of "force"
																			const updatedSubscribers =
																				force ===
																				"subscribe"
																					? [
																							...(prevComment?.subscribers ||
																								[]),
																							currentUserData._id,
																					  ] // Add to followers array
																					: prevComment?.subscribers?.filter(
																							(
																								id
																							) =>
																								id !==
																								currentUserData._id
																					  ); // Remove from followers array
																			return {
																				...(prevComment ||
																					[]),
																				subscribers:
																					updatedSubscribers,
																			};
																		} else {
																			return prevComment;
																		}
																	}
																)
														);
													})
													.catch(() =>
														setLockReplySubmit(
															false
														)
													);
											}}
											style={{
												position: "relative",
												left: "0",
											}}>
											{profileComment?.subscribers?.includes(
												currentUserData?._id
											)
												? "Unsubscribe"
												: "Subscribe"}
										</Button>{" "}
									</>
								)}
								<Button
									onClick={() =>
										setCommentThreadModalShow(false)
									}
									style={{ position: "relative", left: "0" }}>
									Close
								</Button>
							</span>
						</Modal.Footer>
					)}
				</div>
			</Modal>
		);
	};

	return (
		<>
			{profileComments?.map((profileComment, key) => {
				// visibility
				const canSeeProfileComment =
					currentUserData?.isStaff ||
					profileComment?.visibility === "public" ||
					(profileComment?.visibility === "private" &&
						(profileComment?.author === currentUserData?._id ||
							userData?._id === currentUserData?._id));
				// render comments
				return canSeeProfileComment ? (
					<div key={profileComment?._id}>
						<MappedComments
							setThreadModalShow={setCommentThreadModalShow}
							setComments={setProfileComments}
							comments={profileComments}
							setTargetComment={setTargetProfileComment}
							handleDeletion={
								handleProfileCommentDeleteOrObliterate
							}
							userData={userData}
							comment={profileComment}
							author={authors?.find(
								(user) => user._id === profileComment?.author
							)}
							images={images}
							index={key}
							controlComments={controlProfileComments}
							setControlComments={setControlProfileComments}
							currentUserData={currentUserData}
						/>
					</div>
				) : null;
			})}
			{deleteProfileCommentModal()}
			{commentThreadModal()}
		</>
	);
};

const CommentStatus = ({
	parentProfileComment,
	profileComments,
	userData,
	setProfileComments,
}) => {
	const profileComment = profileComments?.find(
		(comment) => comment?._id === parentProfileComment?._id
	);

	// ease of use constants
	const currentUserData = useContext<any>(UserContext);
	const userIsMuted = currentUserData?.mainRoles?.includes(MUTED_USER);
	const isDisliked = profileComment?.user_dislikes?.includes(
		currentUserData?._id
	);
	const isLiked = profileComment?.user_likes?.includes(currentUserData?._id);

	return (
		<>
			<div className="comment-footer__react">
				<button
					className={`comment-footer__react__button${
						isLiked ? "__active-up" : ""
					}`}
					onClick={() => {
						if (
							profileComment &&
							(!currentUserData?._id || userIsMuted)
						)
							return toast.error(`${
								!currentUserData?._id
									? "You must log in to do that."
									: userIsMuted
									? "You can't like/dislike statuses while muted."
									: "Please contact an admin if you see this error"
							}
						`);
						handleReactComment({
							mode: "like",
							comment: profileComment,
							origin: userData,
							currentUserData,
							reactComment: reactProfileComment,
							setComments: setProfileComments,
						});
					}}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isLiked
									? `material-icons`
									: `material-symbols-outlined`
							}>
							thumb_up
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{profileComment?.user_likes?.length || 0}
					</span>
				</button>

				<button
					className={`comment-footer__react__button${
						isDisliked ? "__active-down" : ""
					}`}
					onClick={() => {
						if (
							profileComment &&
							(!currentUserData?._id || userIsMuted)
						)
							return toast.error(`${
								!currentUserData?._id
									? "You must log in to do that."
									: userIsMuted
									? "You can't like/dislike statuses while muted."
									: "Please contact an admin if you see this error"
							}
						`);
						handleReactComment({
							mode: "dislike",
							comment: profileComment,
							origin: userData,
							currentUserData,
							reactComment: reactProfileComment,
							setComments: setProfileComments,
						});
					}}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isDisliked
									? `material-icons`
									: `material-symbols-outlined`
							}>
							thumb_down
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{profileComment?.user_dislikes?.length || 0}
					</span>
				</button>
			</div>
		</>
	);
};

const CommentReplies = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const replyAuthors = props.replyAuthors;
	const history = useHistory();
	return (
		<>
			{props.threadReplies?.map((threadReply, key) => {
				// reply author
				const replyAuthor = replyAuthors?.find(
					(user) => user._id === threadReply?.author
				);

				// define admin comment authors for perms
				const authorIsAdmin =
					replyAuthor?.mainRoles?.includes(ADMIN_MAIN_ROLE);

				return (
					<div key={threadReply?._id}>
						{!threadReply?.editState ? (
							<div
								style={{
									marginBottom: "14px",
									opacity: props.showReplies ? 1 : 0,
								}}
								className={`comment hover-colour thread-fade ${
									threadReply.deleted
										? "deleted-comment"
										: "public-comment"
								} 
										${props.showReplies ? "fade-in-animation" : ""}`}>
								<div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											outlineColor:
												replyAuthor?.icon_outline,
											boxShadow: replyAuthor?.icon_glow
												? `0 0 6px 3px ${replyAuthor?.icon_glow}`
												: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											style={{}}
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													props.images?.defaultAvatar;
											}}
											src={
												replyAuthor?.avatar
													? replyAuthor?.avatar
													: props.images
															?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={replyAuthor?.username}
											onClick={(e) => {
												e.preventDefault();
												if (threadReply?.author) {
													history.push(
														`/users/${threadReply?.author}`
													);
													props.setUserChanged(true);
												}
												window.scrollTo(0, 0);
											}}
											href={`/users/${threadReply?.author}`}>
											{replyAuthor?.username}
										</a>
									</span>
								</div>
								<hr
									style={{
										margin: "-8px 7px 2px 7px",
									}}
								/>
								{/* all buttons for primary profile comments */}
								{threadReply?._id ? (
									<DisplayButtons
										elementsToDisplay={[
											"edit",
											"delete",
											"obliterate",
										]}
										comment={threadReply}
										currentUserData={currentUserData}
										setThreadModalShow={
											props.setCommentThreadModalShow
										}
										setTargetComment={
											props.setTargetProfileComment
										}
										handleDeletion={
											props.handleProfileCommentDeleteOrObliterate
										}
										authorIsAdmin={authorIsAdmin}
										type={"Reply"}
										setComments={props.setThreadReplies}
									/>
								) : null}
								{/* comment + timestamp */}
								<div className="comment__comment-body">
									<ReadMore
										content={threadReply?.content}
										document={threadReply}
									/>
								</div>
								<div className="comment__comment-timestamp">
									Posted on{" "}
									<b>
										{moment(threadReply?.postDate).format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
							</div>
						) : (
							<div>
								<div style={{ marginBottom: "16px" }}>
									<span className="save-edit-comment">
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="cancel">
													Cancel
												</Tooltip>
											}>
											<a
												onClick={() => {
													switchEditState(
														threadReply?._id,
														props.setThreadReplies
													);
													toast.warn(
														"Any changes made to this comment will be discarded upon (comment) page reload."
													);
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingLeft: "2px",
												}}>
												close
											</a>
										</OverlayTrigger>
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="saveChanges">
													Save changes
												</Tooltip>
											}>
											<a
												onClick={() => {
													if (
														threadReply?.content !==
														props
															.controlThreadReplies[
															key
														]?.content
													) {
														if (
															threadReply.content
														) {
															// switch the edit state back to normal
															switchEditState(
																threadReply?._id,
																props.setThreadReplies
															);
															// edit the comment
															editProfileCommentReply(
																props.userData
																	?._id,
																threadReply?._id,
																{
																	content:
																		threadReply?.content,
																}
															).then(() => {
																// make sure the edit state remains false, add the "edited" tag
																const updatedReplies =
																	props.threadReplies.map(
																		(
																			reply,
																			index
																		) => {
																			if (
																				index ===
																				key
																			) {
																				return {
																					...threadReply,
																					edited: true,
																					editState:
																						false,
																				};
																			}
																			return reply;
																		}
																	);
																props.setThreadReplies(
																	updatedReplies
																);
																// update state to account for new changes
																props.setControlThreadReplies(
																	(
																		prevState
																	) => {
																		return {
																			...prevState,
																			[key]: threadReply[
																				key
																			],
																		};
																	}
																);
															});
														} else {
															toast.error(
																"Comment can't be empty"
															);
														}
													} else {
														toast.error(
															"No changes were made"
														);
													}
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingRight: "3px",
												}}>
												save
											</a>
										</OverlayTrigger>
									</span>
									<textarea
										// @ts-ignore
										type="text"
										className="form form-control edit-level-comment"
										value={threadReply?.content}
										onChange={(e) => {
											const updatedComments = [
												...props.threadReplies,
											];
											updatedComments[key].content =
												e.target.value;
											props.setThreadReplies(
												updatedComments
											);
										}}></textarea>
								</div>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
};

const MappedComments = ({
	comment,
	author,
	currentUserData,
	setThreadModalShow,
	setTargetComment,
	handleDeletion,
	userData,
	images,
	setComments,
	comments,
	index,
	controlComments,
	setControlComments,
}) => {
	// define the check for the author being an admin
	const [focussed, setFocussed] = useState<boolean>(false);
	const authorIsAdmin = author?.mainRoles?.includes(ADMIN_MAIN_ROLE);
	const history = useHistory();

	return !comment?.editState ? (
		<div
			className={`hover-colour comment ${
				comment?.visibility === "private"
					? "private-comment"
					: comment?.visibility === "public"
					? comment?.author === userData._id
						? "status-comment"
						: "public-comment"
					: "deleted-comment"
			}`}>
			<div>
				{/* author avatar */}
				<span
					className="comment__icon"
					style={{
						outlineColor: author?.icon_outline,
						boxShadow: author?.icon_glow
							? `0 0 6px 3px ${author?.icon_glow}`
							: "none",
					}}>
					<img
						alt="Author Avatar"
						className="comment__icon__image"
						onError={(e) => {
							// @ts-ignore
							e.target.src = images?.defaultAvatar;
						}}
						src={
							author?.avatar
								? author.avatar
								: images?.defaultAvatar
						}
					/>
				</span>
				{/* author name */}
				<span className="comment__commenter-name">
					<a
						title={author?.username}
						href={`/users/${comment?.author}`}
						onClick={(e) => {
							e.preventDefault();
							window.scrollTo(0, 0);
							history.push(`/users/${comment?.author}`);
						}}>
						{author?.username}
					</a>
				</span>
			</div>
			<hr
				style={{
					margin: "-8px 7px 2px 7px",
				}}
			/>
			{/* all buttons for primary profile comments */}
			{comment?._id ? (
				<DisplayButtons
					elementsToDisplay={[
						"thread",
						"edit",
						"delete",
						"obliterate",
					]}
					comment={comment}
					currentUserData={currentUserData}
					setThreadModalShow={setThreadModalShow}
					setTargetComment={setTargetComment}
					handleDeletion={handleDeletion}
					authorIsAdmin={authorIsAdmin}
					type={"Comment"}
					setComments={setComments}
				/>
			) : null}
			{/* display private or deleted text */}
			{comment?.visibility === "private" ? (
				<span className="comment__private-display">Private</span>
			) : comment?.visibility === "deleted" ? (
				<span className="comment__deleted-display">Deleted</span>
			) : comment?.visibility === "public" &&
			  userData?._id === comment?.author ? (
				// if the profile author is the commentor, add add a status display for like/dislike
				<CommentStatus
					parentProfileComment={comment}
					profileComments={comments}
					userData={userData}
					setProfileComments={setComments}
				/>
			) : (
				""
			)}
			{/* comment + timestamp */}
			<div className="comment__comment-body">
				<div>
					<ReadMore content={comment?.content} document={comment} />
				</div>
			</div>
			{comment?.author !== userData?._id ? (
				<div className="comment__comment-timestamp">
					<b style={{ color: "rgb(255, 255, 104)" }}>
						{moment(comment?.postDate).format(`
									MMM D, YYYY - h:mm A
									`)}
					</b>
				</div>
			) : (
				<b>
					<div className="comment__comment-timestamp">
						{moment(comment?.postDate).format(`
									MMM D, YYYY - h:mm A
									`)}
					</div>
				</b>
			)}
		</div>
	) : (
		<div>
			<div>
				<span
					className={`edit-state-buttons${
						focussed ? "__focus" : ""
					}`}>
					<OverlayTrigger
						delay={{ show: 130, hide: 0 }}
						placement="top"
						overlay={<Tooltip id="cancel">Cancel</Tooltip>}>
						<a
							onClick={() => {
								switchEditState(comment?._id, setComments);
								toast.warn(
									"Any changes made to this comment will be discarded upon (comment) page reload."
								);
							}}
							href={"#!"}
							className="material-symbols-outlined thread-icon"
							style={{
								paddingTop: "3.5px",
								paddingLeft: "2px",
							}}>
							close
						</a>
					</OverlayTrigger>
					<OverlayTrigger
						delay={{ show: 130, hide: 0 }}
						placement="top"
						overlay={
							<Tooltip id="saveChanges">Save changes</Tooltip>
						}>
						<a
							onClick={() => {
								if (
									comment.content !==
									controlComments[index].content
								) {
									if (comment.content) {
										// set the edit state to false
										switchEditState(
											comment?._id,
											setComments
										);
										// edit the comments
										editProfileComment(
											userData?._id,
											comment?._id,
											{
												content: comment?.content,
											}
										).then(() => {
											// make sure the edit state remains false while adding the "edited" tag
											const updatedComments =
												comments.map((comment, key) => {
													if (index === key) {
														return {
															...comment,
															edited: true,
															editState: false,
														};
													}
													return comment;
												});
											setComments(updatedComments);
											// update state to account for new changes
											setControlComments((prevState) => {
												return {
													...prevState,
													[index]: comments[index],
												};
											});
										});
									} else {
										toast.error("Comment can't be empty");
									}
								} else {
									toast.error("No changes were made");
								}
							}}
							href={"#!"}
							className="material-symbols-outlined thread-icon"
							style={{
								paddingTop: "3.5px",
								paddingRight: "3px",
							}}>
							save
						</a>
					</OverlayTrigger>
				</span>
				<textarea
					// @ts-ignore
					type="text"
					onFocus={() => setFocussed(true)}
					onBlur={() => setFocussed(false)}
					maxLength={650}
					style={{ paddingBottom: "25px" }}
					className="form form-control edit-comment__announcement"
					value={comment?.content}
					onChange={(e) => {
						const updatedComments = [...comments];
						updatedComments[index].content = e.target.value;
						setComments(updatedComments);
					}}></textarea>
			</div>
		</div>
	);
};

export default Profile;
