/* local storage */
export const LOGIN_SESSION_NAME = "LSS_login_session";
export const STAFF_TOKEN_NAME = "staffToken";
export const TOAST_MESSAGE = "toastMessage";
export const TOAST_ERROR = "toastError";

/* user roles / punishments */
// main roles
export const LEADER_MAIN_ROLE = "Leader";
export const LEADER_MAIN_ROLE_LOWER = "leader";
export const ADMIN_MAIN_ROLE = "Admin";
export const ADMIN_MAIN_ROLE_LOWER = "admin";
export const MODERATOR_MAIN_ROLE = "Moderator";
export const MODERATOR_MAIN_ROLE_LOWER = "moderator";
export const MITTEN_MAIN_ROLE = "Mitten";
export const MITTEN_MAIN_ROLE_LOWER = "mitten";
export const PARTNER_MAIN_ROLE = "Partner";
export const PARTNER_MAIN_ROLE_LOWER = "partner";
export const DEVELOPER_MAIN_ROLE = "LSS Developer";
export const DEVELOPER_MAIN_ROLE_LOWER = "developer";
export const DONATOR_MAIN_ROLE = "Donator";
export const DONATOR_PLUS_MAIN_ROLE = "Donator+";
export const MEMBER_MAIN_ROLE = "Member";
export const UNVERIFIED_USER_MAIN_ROLE = "Unverified User";
export const BETA_TESTER_MAIN_ROLE = "Beta tester";
export const ELDER_GOD_MAIN_ROLE = "Elder God";
export const ARCHIVE_ACCOUNT = "Archive account";
// subroles
export const REVIEWER_SUBROLE = "Reviewer";
export const SCREENSAVER_SUBROLE = "Screensaver";
export const THEME_BREWER_SUBROLE = "Theme brewer";
export const HELPSQUAD_SUBROLE = "Helpsquad";
export const STARGAZER_SUBROLE = "Stargazer";
// punishments
export const BANNED_USER = "Banned";
export const DEACTIVATED_ACCOUNT = "Deactivated account";
export const MUTED_USER = "Muted";
export const RATE_RESTRICTED_USER = "Rate Restricted";
export const USER_WARNING = "Warning";

/* level status */
export const FEATURED_LEVEL = "Featured";
export const PUBLIC_LEVEL = "Public";
export const PRIVATE_LEVEL = "Private";
export const DELETED_LEVEL = "Deleted";

/* level access / share state */
export const PUBLIC_LEVEL_ACCESS = "Public";
export const PRIVATE_LEVEL_ACCESS = "Private";
export const LINK_LEVEL_ACCESS = "Link";
export const FRIENDS_LEVEL_ACCESS = "Friends";
export const INVITE_LEVEL_ACCESS = "Invite";

/* common response messages */
export const GENERIC_500_ERROR_RESPONSE =
	"Something went wrong, please try again.";
