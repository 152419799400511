// imports
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";

// images
import DefaultThumbnail from "../../../assets/images/DefaultThumbnail.png";

// vectors
import StarEmpty from "../../../assets/vectors/StarEmpty";
import StarFull from "../../../assets/vectors/StarFull";
import StarHalf from "../../../assets/vectors/StarHalf";

// components
import {
	getLevel,
	manageLevelAccess,
	deleteLevel,
	restoreLevel,
	unfeatureLevel,
	featureLevel,
	obliterateLevel,
	addToPlayCount,
	rateLevel,
	getLevelCommentsPage,
	createLevelComment,
	editLevelComment,
	deleteLevelComment,
	obliterateLevelComment,
	addToPlayCountAsGuest,
	reactLevelComment,
	getLevelCommentReplyPage,
	createLevelCommentReply,
	editLevelCommentReply,
	deleteLevelCommentReply,
	obliterateLevelCommentReply,
	getUsersWithAccess,
	subscribeToThread,
	getUserSet,
	removeLevelRate,
	favouriteLevel,
	updateLevelVideo,
	getLevelComment,
	getLevelsList,
} from "../../../api";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../../App";

// page styles
import "./style.scss";

// react-bootstrap components used
import {
	Spinner,
	Modal,
	Button,
	OverlayTrigger,
	Tooltip,
	Nav,
} from "react-bootstrap";
import removeExcessiveBreaks from "../../../functions/removeExcessiveBreaks";
import { mapHighestRole } from "../../../functions/styleAppliers";
import HoverUserCard from "../../HoverUserCard/HoverUserCard";
import {
	handleReactComment,
	switchEditState,
} from "../../../functions/commentHelpers";
import MapStars from "../../Insertions/MapStars";
import ImageStarContainer from "../ImageStarContainer/ImageStarContainer";
import downloadFile from "../../../functions/downloadFile";
import ReadMore from "../../Insertions/ReadMore";

// constants
import {
	ADMIN_MAIN_ROLE,
	DELETED_LEVEL,
	FEATURED_LEVEL,
	FRIENDS_LEVEL_ACCESS,
	GENERIC_500_ERROR_RESPONSE,
	INVITE_LEVEL_ACCESS,
	LINK_LEVEL_ACCESS,
	MUTED_USER,
	PRIVATE_LEVEL,
	PRIVATE_LEVEL_ACCESS,
	PUBLIC_LEVEL,
	PUBLIC_LEVEL_ACCESS,
	RATE_RESTRICTED_USER,
	REVIEWER_SUBROLE,
	SCREENSAVER_SUBROLE,
} from "../../../constants";

// types
import { CommentSort, HoverUserCardPosition } from "./types";

/**
 * An individual level page.
 *
 * @param {*} props
 * @returns
 */
const Level = (props) => {
	// level id shorthand
	const levelID = props.match.params.id;
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};

	// store the latest user data from the UserContext component
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [level, setLevel] = useState<any>(null);
	const [thumbnail, setThumbnail] = useState<string | null>("");
	const [levelChanged, setLevelChanged] = useState<boolean>(true);
	const [levelMessage, setLevelMessage] = useState<string>("");

	const [commentHighlight, setCommentHighlight] = useState<boolean>(false);

	const [commentSort, setCommentSort] = useState<CommentSort>("priority");
	const [levelComments, setLevelComments] = useState<any[]>([]);
	const [previousPage, setPreviousPage] = useState<any>(null);
	const [controlLevelComments, setControlLevelComments] = useState<any[]>([]);
	const [currentUserCommentCount, setCurrentUserCommentCount] =
		useState<number>(0);

	const [levelCommentsChanged, setLevelCommentsChanged] =
		useState<boolean>(true);
	const [commentsQuerying, setCommentsQuerying] = useState<boolean>(false);
	const [numberOfPages, setNumberOfPages] = useState<number>(0);

	const [prevThreadPage, setPrevThreadPage] = useState<number>(-1);

	const [levelCommentToDelete, setLevelCommentToDelete] = useState<any>("");
	const [levelObliteration, setLevelObliteration] = useState<boolean>(false);
	const [rolledRandomLevel, setRolledRandomLevel] = useState<boolean>(false);

	const [transition, setTransition] = useState<boolean>(true);
	const [transitionNormal, setTransitionNormal] = useState<boolean>(false);
	const [transitionTime, setTransitionTime] = useState<string>(
		"opacity 0.35s ease-in-out"
	);

	const [lockCommentSubmit, setLockCommentSubmit] = useState<boolean>(false);

	const [starRate, setStarRate] = useState<number>(0);
	const [levelComment, setLevelComment] = useState<string>(
		sessionStorage.getItem(`levelComment${levelID}`) || ""
	);
	const [noCommentBox, setNoCommentBox] = useState<boolean>(false);
	const [levelCodeModalShow, setLevelCodeModalShow] =
		useState<boolean>(false);
	const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
	const [deleteCommentModalShow, setDeleteCommentModalShow] =
		useState<boolean>(false);
	const [featureModalShow, setFeatureModalShow] = useState<boolean>(false);

	const [cantPostComments, setCantPostComments] = useState<boolean>(false);

	const [accessManageModalShow, setAccessManageModalShow] =
		useState<boolean>(false);
	const [currentLevelAccess, setCurrentLevelAccess] = useState<string>("");
	const [userToManage, setUserToManage] = useState<any>("");
	const [usersWithAccess, setUsersWithAccess] = useState<any[]>([]);

	// hook initializations
	const history = useHistory();
	const query = useQuery();

	// URL params for checking url (randomizer)
	const urlParams = new URLSearchParams(window.location.search);
	const gameID = urlParams.get("gameID");

	// display specific constants for simplification
	const game = props?.gameProperties?.find(
		(game) => game?.internalID === level?.game
	);
	const userIsStaff = currentUserData?.isStaff;
	const userIsAdmin = currentUserData?.mainRoles.includes(ADMIN_MAIN_ROLE);
	const userIsReviewer =
		currentUserData?.subRoles?.includes(REVIEWER_SUBROLE);
	const featuredLevel = level?.status === FEATURED_LEVEL;
	const deletedLevel = level?.status === DELETED_LEVEL;
	const privateLevel = level?.status === PRIVATE_LEVEL;
	const returnShareState = () => {
		const prefix = " - ";
		if (!privateLevel || level?.shareState === PRIVATE_LEVEL_ACCESS)
			return "";
		if (level?.shareState === FRIENDS_LEVEL_ACCESS)
			return prefix + "Friend only";
		if (level?.shareState === INVITE_LEVEL_ACCESS)
			return prefix + "Invite only";
		if (level?.shareState === LINK_LEVEL_ACCESS)
			return prefix + "Everyone with link";
	};
	const userIsMuted = currentUserData?.mainRoles.includes(MUTED_USER);
	const userIsRateRestricted =
		currentUserData?.mainRoles.includes(RATE_RESTRICTED_USER);

	/*  permissions and restrictions */
	const verifiedUser = currentUserData?.verified === true;
	const levelAuthor = level?.author;
	const socialSetting = parseInt(levelAuthor?.social?.[0]);
	const socialSettingBlock =
		socialSetting === 2 || // disable
		(socialSetting === 1 && // friends only
			!currentUserData?.friends?.includes(levelAuthor?._id));
	const userIsLevelAuthor = levelAuthor?._id === currentUserData?._id;

	const canRateLevel =
		(currentUserData?._id &&
			!userIsLevelAuthor &&
			!userIsRateRestricted &&
			verifiedUser) ||
		(userIsStaff && !userIsLevelAuthor);
	const commentImpaired =
		!userIsStaff &&
		!userIsReviewer &&
		(userIsMuted || !verifiedUser || socialSettingBlock);

	// level comment pagination
	const page =
		parseInt(query.get("page") ?? "0") > 0
			? parseInt(query.get("page") ?? "0")
			: 1;
	// pagination for threads
	const threadpage =
		parseInt(query.get("threadpage") ?? "0") > 0
			? parseInt(query.get("threadpage") ?? "0")
			: 1;

	useEffect(() => {
		if (level?._id !== levelID && level?._id) {
			setLevelChanged(true);
			setLevel([]);
			setLevelCommentsChanged(true);
			setLevelComments([]);
			setPreviousPage(-1);
			setUsersWithAccess([]);
		}
	}, [level?._id, levelID]);

	// retrieve level info with ID from props
	useEffect(() => {
		if (levelChanged) {
			setLevelChanged(false);
			getLevel({
				id: levelID,
				game: "",
				userID: "",
				keep: true,
			}).then((response) => {
				const levelResponse = response?.data?.level;
				// dont set a star rate if theres no level
				if (Object.keys(levelResponse).length > 0) {
					setStarRate(Number(levelResponse?.hasRated));
					setCurrentUserCommentCount(
						levelResponse?.commenters?.filter(
							(commenter) => commenter === currentUserData?._id
						)?.length || 0
					);
					setLevel(levelResponse);
					const image = new Image();
					image.src = levelResponse?.thumbnail;
					image.onload = () => setThumbnail(levelResponse?.thumbnail);
					image.onerror = () => setThumbnail(DefaultThumbnail);
					setVideoUrl(levelResponse?.video);
				}

				setTransitionNormal(true);
				setTransitionTime("opacity 0.5s ease-in-out");
				setCurrentLevelAccess(
					levelResponse?.shareState || PRIVATE_LEVEL_ACCESS
				);
				setLevelMessage(response?.data?.message);

				setTimeout(() => {
					setTransitionNormal(false);
					setTransition(false);
				}, 1000);
				document.title = `${
					levelResponse?.name || "No access"
				} - Level Share Square`;
			});
		}
	}, [
		levelChanged,
		levelID,
		commentImpaired,
		currentUserData,
		userIsAdmin,
		userIsStaff,
	]);

	useEffect(() => {
		setCantPostComments(
			!userIsAdmin &&
				(level?.visibility?.includes("Deleted") ||
					commentImpaired ||
					!currentUserData?._id)
		);
	}, [level, commentImpaired, currentUserData, userIsAdmin]);

	// retrieve level comments
	useEffect(() => {
		// dont execute if on the exact same page
		if (
			(previousPage === page && level?._id === props.match.params.id) ||
			commentsQuerying
		)
			return setLevelCommentsChanged(false);

		if (levelCommentsChanged && levelID) {
			setLevelCommentsChanged(false);
			setLevelComments([]);
			setCommentsQuerying(true);
			const id = levelID;
			getLevelCommentsPage(id, { page, sort: commentSort })
				.then((response) => {
					if (response?.data) {
						setPreviousPage(page);
						setLevelComments(response.data.levelComments);
						setControlLevelComments(response.data.levelComments);
						setNumberOfPages(response.data.numberOfPages || 1);
					}
				})
				.finally(() => setCommentsQuerying(false));
		}
	}, [
		levelCommentsChanged,
		levelID,
		page,
		previousPage,
		currentUserData,
		level?._id,
		props.match.params.id,
		commentsQuerying,
		commentSort,
	]);

	// handle rolling a random level
	useEffect(() => {
		if (rolledRandomLevel) {
			setRolledRandomLevel(false);
			setPreviousPage(-1);
			getLevelsList({
				random: true,
				game: gameID,
				count: 3,
				safemode: 2,
			}).then((response) => {
				if (response?.data) {
					setRandomLevelResponse(response?.data);
					setRandomLevelModalShow(true);
				}
			});
		}
	}, [rolledRandomLevel, gameID, history, currentUserData?._id]);

	// states for rating
	const [ratingModalShow, setRatingModalShow] = useState<boolean>(false);

	// handle the submission of a star rating
	const handleStarRating = (newRating, force?) => {
		// submit star rating if user has actually picked one
		if (newRating > 0.5) {
			// confirmation
			if (level?.hasRated !== 0 && force !== true)
				return setRatingModalShow(newRating);
			// perform the request
			toast.info(
				`${level?.hasRated !== 0 ? "Changing" : "Adding"} star rate...`
			);
			setStarRate(newRating);
			rateLevel(level?._id, { starRate: newRating }).then((response) => {
				if (response?.data) {
					setLevel((prevState) => {
						const data = response?.data;
						const newLevel = {
							...prevState,
							rates: [...prevState?.rates, newRating],
							rating: data.rating,
							hasRated: newRating,
						};
						return newLevel;
					});

					// update the rates that should be mapped
					setRatesToMap((prevState) => {
						const newState = [...prevState, newRating];
						return newState;
					});

					// update the rate author state
					setVoteAuthors((prevState) => {
						const newState = [
							...prevState,
							{
								username: currentUserData?.username,
								_id: currentUserData?._id,
								index: prevState?.length,
							},
						];
						return newState;
					});

					// update the userdata
					props.setCurrentUserData((prevState) => {
						return {
							...prevState,
							rates: parseInt(prevState?.rates + 1),
						};
					});
				}
			});
		} else {
			toast.error(
				"The minimum rating you have to give a level is 1 star or higher."
			);
		}
	};

	// accompanied modal
	const ratingModal = () => {
		return (
			<Modal
				show={ratingModalShow !== false}
				onHide={() => setRatingModalShow(false)}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<Modal.Body>
					{ratingModalShow === false ? (
						<h2 style={{ textAlign: "center" }}>Closing...</h2>
					) : (
						<h4 style={{ textAlign: "center" }}>
							Are you sure you want to change your rate to{" "}
							{ratingModalShow}/5 stars?
						</h4>
					)}
				</Modal.Body>
				<Modal.Footer className="mx-auto" style={{ margin: "0 auto" }}>
					<Button
						onClick={() => {
							handleStarRating(ratingModalShow, true);
							setRatingModalShow(false);
						}}>
						Yes
					</Button>
					<Button
						onClick={() => {
							setRatingModalShow(false);
						}}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	// completely delete a level from the database
	useEffect(() => {
		if (levelObliteration) {
			if (userIsAdmin) {
				toast.error(
					"Final check, you have no idea of the horrors to come.",
					{
						onClick: async () => {
							obliterateLevel(levelID).then(() =>
								history.push("/")
							);
						},
					}
				);
			} else {
				toast.success(
					"There where the flames burn, you shall not prevail. (Only admins can permanently delete levels)"
				);
			}

			setLevelObliteration(false);
		}
	}, [
		levelObliteration,
		userIsAdmin,
		currentUserData,
		level,
		levelID,
		history,
	]);

	// card position for //? HoverUserCard
	const [cardPosition, setCardPosition] = useState<HoverUserCardPosition>({
		x: 0,
		y: 0,
	});
	const [showCard, setShowCard] = useState<boolean>(false);

	// clicking the username
	const handleAuthorClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// check if the user is on a desktop device
		if (
			(window.innerWidth > 550 && window.innerHeight > 550) ||
			showCard === true
		) {
			return history.push(`/users/${levelAuthor?._id}`);
		}
		// function to trigger when on pc
		if (showCard === false) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(true);
			// redirect if the user has already clicked
		}
	};

	// hovering the username
	const handleHover = (e, show) => {
		e.preventDefault();
		e.stopPropagation();

		// don't fire if on mobile
		if (window.innerWidth <= 550 || window.innerHeight <= 550) return;

		// show card
		if (show === true) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(true);
		}

		// hide card
		if (show === false) {
			return setShowCard(false);
		}
	};

	// play button press opens game, copies code, and adds to play count
	const handlePlayButtonClick = (e) => {
		e.preventDefault();

		// get level code and save to clipboard
		if (level?.code && level.game !== 4)
			navigator.clipboard.writeText(level?.code).then(
				function () {
					toast.success("Copied level code!");
				},
				function () {
					toast.error("Code could not be copied.");
				}
			);
		if (level?.fileData?.data) {
			// Assuming level.fileData contains the binary data received from the server
			const binaryData = new Uint8Array(level?.fileData?.data);
			const blob = new Blob([binaryData], {
				type: "application/zip",
			});

			// Check if the binaryData is valid
			if (blob instanceof Blob) downloadFile(blob, level);
		}

		// add to play count
		handleAddToPlayCount();

		// check whether the user is trying to open SMC or YFS
		if (level.game === 0 || level.game === 1) {
			toast.info(`Opening ${game?.name}...`);
			const gameTab = props?.gameTabs?.[level?.game];

			// open game after delay
			return setTimeout(() => {
				// focus if it exists & is open
				if (gameTab && !gameTab.closed) return gameTab.focus();
				// otherwise open a new one
				const gameWindow = window.open(
					`${game?.url}`,
					Math.random().toString()
				);
				props.setGameTabs((prevState) => {
					return { ...prevState, [level.game]: gameWindow };
				});
			}, 1000);
		}
		// SMF & SM127 don't open a new window (no additional behavior as of now)
		if (level.game === 2 || level.game === 3) return;
		// SM64 downloads a file instead
		if (level.game === 4) return toast.info("Downloading file...");
	};

	const handleAddToPlayCount = () => {
		if (!level?.hasPlayed) {
			const addToPlayCountPromise = currentUserData
				? addToPlayCount(levelID)
				: addToPlayCountAsGuest(levelID);

			return addToPlayCountPromise.then((response) => {
				if (response?.data?.success === true) {
					return setLevel((prevState) => ({
						...prevState,
						hasPlayed: true,
						plays: parseInt(prevState.plays) + 1,
					}));
				}
			});
		}
	};

	// handle the submission of a level comment
	const handleCommentSubmit = (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		// return if level author is blocked
		if (currentUserData?.blocked_users?.includes(levelAuthor._id))
			return toast.error(
				"You can't comment on levels of users who you've blocked"
			);

		// return if comment author is blocked
		if (levelAuthor?.blocked_users?.includes(currentUserData?._id))
			return toast.error("You're blocked by the author of this level.");
		// minimum length for reviews
		if (commentHighlight === true && levelComment?.length < 400) {
			setLockCommentSubmit(false);
			return toast.error(
				"Reviews must have a minimum length of 400 characters."
			);
		}

		// submit level comment if user has actually entered one
		if (levelComment) {
			setLockCommentSubmit(true);
			toast.info("Adding level comment...");
			history.push(window.location.pathname + query ? `?${query}` : "");

			createLevelComment(levelID, {
				content: removeExcessiveBreaks(levelComment),
				highlighted: commentHighlight,
			})
				.then((response) => {
					setLockCommentSubmit(false);
					if (response) {
						//update level comments state
						setLevelComments((prevState) => {
							const newLevelComments = [
								{
									...response.data.newLevelComment,
									author: currentUserData,
								},
								...prevState,
							];
							return newLevelComments;
						});
						setLevelComment("");
						sessionStorage.removeItem(`levelComment${levelID}`);
						setCurrentUserCommentCount((prevState) => {
							const newCount = prevState + 1;
							if (newCount >= 3) setNoCommentBox(true);
							return newCount;
						});
					}
				})
				.catch(() => setLockCommentSubmit(false));
		} else {
			toast.error(
				"Please enter a level comment before trying to submit it!"
			);
		}
	};

	// fetch all users with access to a private level
	useEffect(() => {
		if (
			currentLevelAccess === INVITE_LEVEL_ACCESS &&
			usersWithAccess?.length === 0 &&
			level?.access?.length > 0 &&
			accessManageModalShow === true
		) {
			getUsersWithAccess(levelID).then((response) =>
				setUsersWithAccess(response?.data?.users)
			);
		}
	}, [
		currentLevelAccess,
		usersWithAccess?.length,
		level?.access?.length,
		accessManageModalShow,
		levelID,
	]);

	// add a user to the access of a private level
	const handleManageAccess = (e) => {
		if ((e.type === "click" || e.key === "Enter") && !lockCommentSubmit) {
			if (level?.shareState === currentLevelAccess) {
				setLockCommentSubmit(true);
				manageLevelAccess(levelID, {
					mode: "users",
					target: userToManage,
					force: "add",
				})
					.then((response) => {
						setLockCommentSubmit(false);
						if (response) {
							setLevel(response.data.updatedLevel);
							setUserToManage("");
							setUsersWithAccess((prevState) => [
								...prevState,
								response.data.user,
							]);
						}
					})
					.catch(() => setLockCommentSubmit(false));
			} else
				toast.error(
					"Please save your changes before adding/removing any people."
				);
		}
	};
	// manage access for private levels
	const accessManageModal = () => {
		return (
			<Modal
				show={accessManageModalShow}
				onHide={() => setAccessManageModalShow(false)}
				size={
					currentLevelAccess !== INVITE_LEVEL_ACCESS
						? undefined
						: "xl"
				}
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span className="modal-fill">
					<Modal.Body
						style={{
							height:
								currentLevelAccess !== INVITE_LEVEL_ACCESS
									? currentLevelAccess !== PUBLIC_LEVEL_ACCESS
										? "30vh"
										: "45vh"
									: "80vh",
							textAlign: "center",
							overflowY: "auto",
						}}>
						<h3 className="yellow">
							Share private access with friends
						</h3>
						<label htmlFor="select-access">Access mode</label>
						<br />
						<select
							id="select-access"
							name="select-access"
							className="access-form"
							value={currentLevelAccess}
							onChange={(e) =>
								setCurrentLevelAccess(e.target.value)
							}>
							<option value={PUBLIC_LEVEL_ACCESS}>Public</option>
							<option value={PRIVATE_LEVEL_ACCESS}>
								Private
							</option>
							<option value={LINK_LEVEL_ACCESS}>
								Members with link
							</option>
							<option value={FRIENDS_LEVEL_ACCESS}>
								Friends only
							</option>
							<option value={INVITE_LEVEL_ACCESS}>
								Invite only
							</option>
						</select>
						{currentLevelAccess === INVITE_LEVEL_ACCESS ? (
							<>
								<hr />
								<input
									type="text"
									className="access-form"
									id="manage-users"
									name="manage-users"
									maxLength={24}
									style={{ borderRadius: "8px 0 0 8px" }}
									placeholder="Invite by username or ID"
									value={userToManage}
									onKeyDown={(e) => {
										handleManageAccess(e);
									}}
									onChange={(e) => {
										setUserToManage(e.target.value);
									}}
								/>
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id="addUser">Add user</Tooltip>
									}>
									<button
										className="btn btn-primary"
										style={{
											marginBottom: "1px",
											height: "31px",
											width: "36px",
											borderRadius: "0 8px 8px 0",
										}}
										type="button"
										id="manage-users"
										name="manage-users"
										disabled={
											!userToManage || lockCommentSubmit
										}
										onClick={(e) => {
											handleManageAccess(e);
										}}>
										<span
											className="material-icons"
											style={{
												position: "relative",
												bottom: "5px",
												right: "9px",
												fontSize: "30px",
											}}>
											add
										</span>
									</button>
								</OverlayTrigger>
								<div>
									<hr style={{ marginBottom: "4px" }} />
									<div
										className="row"
										style={{
											overflowY: "scroll",
											overflow: "auto",
											height: "48vh",
											paddingTop: "2px",
										}}>
										<span className="col-6 col-border">
											{usersWithAccess
												?.slice(0, 10)
												?.map((user, key) => {
													return (
														<>
															<span
																className="row"
																key={user?._id}
																style={{
																	textAlign:
																		"center",
																	marginLeft:
																		"-2px",
																	marginBottom:
																		"-16px",
																}}>
																<div
																	className="col-10"
																	style={{
																		textAlign:
																			"left",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																		whiteSpace:
																			"nowrap",
																	}}>
																	<span
																		className="main-roles"
																		style={{
																			color: mapHighestRole(
																				user?.mainRoles,
																				"color"
																			),
																			borderColor:
																				mapHighestRole(
																					user?.mainRoles,
																					"color"
																				),
																			boxShadow: `0 0 1px 1px ${mapHighestRole(
																				user?.mainRoles,
																				"color"
																			)}`,
																			position:
																				"relative",
																		}}>
																		&nbsp;
																		{mapHighestRole(
																			user?.mainRoles,
																			"role"
																		)}
																		&nbsp;
																	</span>
																	&nbsp;&nbsp;
																	<a
																		href={`/users/${user._id}`}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			history.push(
																				`/users/${user._id}`
																			);
																		}}>
																		{
																			user.username
																		}
																	</a>
																	&nbsp;
																</div>
																<span
																	className="col-2"
																	style={{
																		textAlign:
																			"center",
																	}}>
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="removeAccess">
																				Remove
																				access
																			</Tooltip>
																		}>
																		<span className="material-icons">
																			<button
																				onClick={() => {
																					manageLevelAccess(
																						levelID,
																						{
																							mode: "users",
																							target: user._id,
																							force: "remove",
																						}
																					).then(
																						(
																							response
																						) => {
																							if (
																								response
																							) {
																								setLevel(
																									response
																										.data
																										.updatedLevel
																								);
																								setUsersWithAccess(
																									(
																										prevState
																									) =>
																										prevState.filter(
																											(
																												user
																											) =>
																												user._id !==
																												response
																													.data
																													.user
																													._id
																										)
																								);
																							}
																						}
																					);
																				}}
																				className="hydrawisp-red small-action-button">
																				person_off
																			</button>
																		</span>
																	</OverlayTrigger>
																</span>
															</span>
															<hr
																style={{
																	marginBottom:
																		"4px",
																}}
															/>
														</>
													);
												})}
										</span>
										<span className="col-6">
											{usersWithAccess
												?.slice(10, 20)
												?.map((user, key) => {
													return (
														<>
															<span
																className="row"
																key={user?._id}
																style={{
																	textAlign:
																		"center",
																	marginLeft:
																		"-2px",
																	marginBottom:
																		"-16px",
																}}>
																<div
																	className="col-10"
																	style={{
																		textAlign:
																			"left",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																		whiteSpace:
																			"nowrap",
																	}}>
																	<span
																		className="main-roles"
																		style={{
																			color: mapHighestRole(
																				user?.mainRoles,
																				"color"
																			),
																			borderColor:
																				mapHighestRole(
																					user?.mainRoles,
																					"color"
																				),
																			boxShadow: `0 0 1px 1px ${mapHighestRole(
																				user?.mainRoles,
																				"color"
																			)}`,
																			position:
																				"relative",
																		}}>
																		&nbsp;
																		{mapHighestRole(
																			user?.mainRoles,
																			"role"
																		)}
																		&nbsp;
																	</span>
																	&nbsp;&nbsp;
																	<a
																		href={`/users/${user?._id}`}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			history.push(
																				`/users/${user?._id}`
																			);
																		}}>
																		{
																			user.username
																		}
																	</a>
																	&nbsp;
																</div>
																<span className="col-2">
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="removeAccess">
																				Remove
																				access
																			</Tooltip>
																		}>
																		<span className="material-icons">
																			<button
																				onClick={() => {
																					manageLevelAccess(
																						levelID,
																						{
																							mode: "users",
																							target: user._id,
																							force: "remove",
																						}
																					).then(
																						(
																							response
																						) => {
																							if (
																								response
																							) {
																								setLevel(
																									response
																										.data
																										.updatedLevel
																								);
																								setUsersWithAccess(
																									(
																										prevState
																									) =>
																										prevState.filter(
																											(
																												user
																											) =>
																												user._id !==
																												response
																													.data
																													.user
																													._id
																										)
																								);
																							}
																						}
																					);
																				}}
																				className="hydrawisp-red small-action-button">
																				person_off
																			</button>
																		</span>
																	</OverlayTrigger>
																</span>
															</span>
															<hr
																style={{
																	marginBottom:
																		"4px",
																}}
															/>
														</>
													);
												})}
										</span>
									</div>
								</div>
							</>
						) : currentLevelAccess === PUBLIC_LEVEL_ACCESS ? (
							<div>
								<hr />
								You can publish your level on the edit level
								page
								<br />
								<a
									href={`/levels/edit/${levelID}?scrollTo=visibility`}
									onClick={(e) => {
										e.preventDefault();
										history.push(
											`/levels/edit/${levelID}?scrollTo=visibility`
										);
									}}>
									<button className="btn btn-primary">
										Publish my level
									</button>
								</a>
							</div>
						) : null}
					</Modal.Body>
					<Modal.Footer className="mx-auto">
						<div style={{ margin: "0 auto" }}>
							<Button
								className="btn-primary__special"
								disabled={
									currentLevelAccess === level?.shareState
								}
								onClick={() => {
									toast.info("Changing access...");
									manageLevelAccess(levelID, {
										mode: "visibility",
										target: currentLevelAccess,
									}).then((response) => {
										setLevel((prevState) => {
											return {
												...prevState,
												shareState:
													response.data.shareState,
											};
										});
									});
								}}>
								Save
							</Button>
							&nbsp;&nbsp;
							<Button
								className="btn-primary__danger"
								onClick={() => setAccessManageModalShow(false)}>
								Close
							</Button>
						</div>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// states for random level modal
	const [randomLevelModalShow, setRandomLevelModalShow] =
		useState<boolean>(false);
	const [randomLevelResponse, setRandomLevelResponse] = useState<any>(null);

	const handleLevelChange = (key) => {
		setRandomLevelModalShow(false);
		setLevel(null);
		getLevel({
			id: randomLevelResponse?.levels?.[key]?._id,
			game: "",
			keep: true,
		}).then((response) => {
			const data = response?.data;
			history.push(
				`/levels/${data?.level?._id}?gameID=${response?.data?.level?.game}`
			);
			setLevel(data?.level);
			setCurrentUserCommentCount(
				randomLevelResponse?.commenters?.filter(
					(commenter) => commenter === currentUserData?._id
				)?.length || 0
			);
			setLevelCommentsChanged(true);
			const image = new Image();
			image.src = data?.level?.thumbnail;
			image.onload = () => setThumbnail(data?.level?.thumbnail);
			image.onerror = () => setThumbnail(DefaultThumbnail);
			setVideoUrl(data?.level?.video);
		});
	};

	const randomLevelModal = () => {
		return (
			<Modal
				show={randomLevelModalShow}
				onHide={() => setRandomLevelModalShow(false)}
				size="sm"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span style={{ backgroundColor: "rgba(71, 181, 255, 0.42)" }}>
					<Modal.Body
						style={{
							textAlign: "center",
						}}>
						<h3 className="yellow">Pick a level!</h3>
						<hr />
						<div
							style={{
								display: "flex",
								flexFlow: "column",
								alignItems: "center",
							}}>
							{randomLevelResponse &&
								randomLevelResponse?.levels &&
								randomLevelResponse?.levels?.map(
									(level, key) => {
										return (
											<div
												key={key}
												title={level?.name}
												className="random-level-modal"
												onClick={() =>
													handleLevelChange(key)
												}>
												<ImageStarContainer
													level={level}
													gameProperties={
														props?.gameProperties
													}
												/>
											</div>
										);
									}
								)}
						</div>
					</Modal.Body>
				</span>
			</Modal>
		);
	};

	const handleFavouriteLevel = () => {
		if (!currentUserData?._id)
			return toast.error("You must log in to do that!");
		favouriteLevel(level?._id).then(() => {
			if (!currentUserData?.favorite_levels?.includes(level?._id)) {
				// update the level
				setLevel((prevLevel) => {
					return {
						...prevLevel,
						favourites: prevLevel?.favourites + 1 || 1,
					};
				});
				// Add it to currentUserData
				return props.setCurrentUserData((prevState) => {
					const favouriteLevels =
						currentUserData?.favorite_levels || [];
					return {
						...prevState,
						favorite_levels: [...favouriteLevels, level?._id],
					};
				});
			}
			// else remove 1 from the level's favourites
			setLevel((prevLevel) => {
				return {
					...prevLevel,
					favourites: prevLevel?.favourites - 1,
				};
			});
			// also from the current users
			return props.setCurrentUserData((prevState) => {
				return {
					...prevState,
					favorite_levels: prevState?.favorite_levels?.filter(
						(id) => id !== level?._id
					),
				};
			});
		});
	};

	// confirmation dialog for deleting a level comment
	const deleteLevelCommentModal = () => {
		return (
			<Modal
				show={deleteCommentModalShow}
				onHide={() => setDeleteCommentModalShow(false)}
				size="sm"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Body>
					<h4 style={{ textAlign: "center" }}>
						Are you sure you want to{" "}
						{levelCommentToDelete?.visibility !== "deleted"
							? "delete"
							: "obliterate"}
						&nbsp; this level comment?
					</h4>
				</Modal.Body>
				<Modal.Footer className="mx-auto">
					<Button
						onClick={() => {
							toast.info(
								levelCommentToDelete?.visibility !== "deleted"
									? "Deleting level comment..."
									: "Obliterating level comment..."
							);
							const levelCommentID = levelCommentToDelete._id;

							//! delete level comment
							if (
								levelCommentToDelete?.visibility !== "deleted"
							) {
								// simple deletion
								deleteLevelComment(
									levelID,
									levelCommentID
								).then((response) => {
									if (
										currentUserData?._id ===
										response?.data?.levelComment?.author
									) {
										setCurrentUserCommentCount(
											(prevState) => {
												const newCount = prevState - 1;
												if (newCount <= 3)
													setNoCommentBox(false);
												return newCount;
											}
										);
										setNoCommentBox(false);
									}
									// update state of level comment
									setLevelComments((prevState) => {
										return prevState.map((comment) => {
											if (
												comment._id === levelCommentID
											) {
												return {
													...comment,
													visibility: "deleted",
												};
											}
											return comment;
										});
									});
									// update state of level
									setLevel((prevState) => ({
										...prevState,
										commenters: prevState.commenters.filter(
											(commenter) =>
												commenter !==
												response.data.levelComment
													?.author?._id
										),
									}));
								});
							} else {
								// obliterate it
								obliterateLevelComment(
									levelID,
									levelCommentID
								).then(() => {
									setLevelComments((prevComments) =>
										prevComments.filter(
											(levelComment) =>
												levelComment._id !==
												levelCommentID
										)
									);
								});
							}

							// reset values
							setLevelCommentToDelete("");
							setDeleteCommentModalShow(false);
							setLockCommentSubmit(false);
						}}>
						Yes
					</Button>
					<Button onClick={() => setDeleteCommentModalShow(false)}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	// Modal that shows the level code
	const levelCodeModal = () => {
		return (
			<Modal
				show={levelCodeModalShow}
				onHide={() => setLevelCodeModalShow(false)}
				size="lg"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span className="modal-fill">
					<Modal.Body>
						<h4 style={{ textAlign: "center" }}>
							Copy this level code and paste it in-game:
						</h4>
						<textarea readOnly={true}>{level?.code}</textarea>
					</Modal.Body>
					<Modal.Footer
						className="mx-auto"
						style={{ margin: "0 auto", width: "max-content" }}>
						<Button
							onClick={() =>
								navigator.clipboard.writeText(level?.code).then(
									function () {
										handleAddToPlayCount();
										toast.success("Copied level code!");
									},
									function () {
										toast.error(
											"Code could not be copied."
										);
									}
								)
							}>
							Copy to clipboard
						</Button>
						<Button onClick={() => setLevelCodeModalShow(false)}>
							Close
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// handle updating a level
	const handleUpdateLevel = (action) => {
		switch (action) {
			case "delete":
				deleteLevel(levelID).then(() => {
					setLevel((prevState) => {
						const updatedState = {
							...prevState,
							status: DELETED_LEVEL,
						};
						return updatedState;
					});
					if (!userIsStaff)
						history.push(`/levels/user/${currentUserData?._id}`);
				});
				break;
			case "private":
				restoreLevel(levelID).then(() => {
					setLevel((prevState) => {
						const updatedState = {
							...prevState,
							status: PRIVATE_LEVEL,
						};
						return updatedState;
					});
				});
				break;
			case "unfeature":
				unfeatureLevel(levelID).then(() => {
					setLevel((prevState) => {
						const updatedState = {
							...prevState,
							status: PUBLIC_LEVEL,
						};
						return updatedState;
					});
				});
				break;
			case "feature":
				featureLevel(levelID, { duration: featureDuration }).then(
					() => {
						setLevel((prevState) => {
							const updatedState = {
								...prevState,
								status: FEATURED_LEVEL,
							};
							return updatedState;
						});
					}
				);
				break;
			default:
				toast.error(GENERIC_500_ERROR_RESPONSE);
				break;
		}
	};

	const [featureDuration, setFeatureDuration] = useState<any>(4);

	// confirmation dialog for (un)featuring a level
	const featureModal = () => {
		return (
			<Modal
				show={featureModalShow}
				onHide={() => setFeatureModalShow(false)}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<Modal.Body>
					{featuredLevel ? (
						<h4 style={{ textAlign: "center" }}>
							Are you sure you want to unfeature &nbsp;
							<span className="blue">{level?.name}</span>?
						</h4>
					) : (
						<>
							<h4 style={{ textAlign: "center" }}>
								How long should this level be featured for?
							</h4>
							<hr />
							<input
								type="range"
								id="slider"
								name="slider"
								min="1"
								max="14"
								step="1"
								value={featureDuration}
								onChange={(e) =>
									setFeatureDuration(e.target.value)
								}
							/>
							<div className="yellow center-textoutput">
								{featureDuration} day
								{featureDuration > 1 && "s"}
							</div>
						</>
					)}
				</Modal.Body>
				<Modal.Footer className="mx-auto">
					<Button
						className={
							featuredLevel
								? "btn btn-primary__danger"
								: "btn btn-primary__special"
						}
						onClick={() => {
							toast.info(
								`${
									featuredLevel ? "Unfeaturing" : "Featuring"
								} level...`
							);
							if (featuredLevel) {
								handleUpdateLevel("unfeature");
							} else {
								handleUpdateLevel("feature");
							}
							setFeatureModalShow(false);
						}}>
						Confirm
					</Button>
					<Button onClick={() => setFeatureModalShow(false)}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	// confirmation dialog for deleting this level
	const deleteModal = () => {
		return (
			<Modal
				show={deleteModalShow}
				onHide={() => setDeleteModalShow(false)}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<Modal.Body>
					<h4 style={{ textAlign: "center" }}>
						Are you sure you want to{" "}
						{level?.status === DELETED_LEVEL ? "restore" : "delete"}
						&nbsp;<span className="blue">{level?.name}</span>?
					</h4>
				</Modal.Body>
				<Modal.Footer className="mx-auto">
					<Button
						onClick={() => {
							toast.info(
								`${
									deletedLevel ? "Restoring" : "Deleting"
								} level...`
							);
							if (deletedLevel) handleUpdateLevel("private");
							else handleUpdateLevel("delete");

							setDeleteModalShow(false);
						}}>
						Yes
					</Button>
					<Button onClick={() => setDeleteModalShow(false)}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	// states for vote logs
	const [voteLogsModalShow, setVoteLogsModalShow] = useState<boolean>(false);
	const [voteAuthorsChanged, setVoteAuthorsChanged] =
		useState<boolean>(false);
	const [voteAuthors, setVoteAuthors] = useState<object[]>([]);
	const [ratesToMap, setRatesToMap] = useState<number[]>([]);
	const [numberOfVotePages, setNumberOfVotePages] = useState<number>(0);
	const [prevRatepage, setPrevRatepage] = useState<number>(-1);
	// define the page, limit and maximum, including what to display
	const ratepage = parseInt(query.get("ratepage") ?? "1") || 1;
	const limit = 20;

	// useEffect hook for getting authors, staff only
	useEffect(() => {
		if (!userIsStaff) {
			setVoteAuthorsChanged(false);
			setRatesToMap(
				level?.rates?.slice((ratepage - 1) * limit, ratepage * limit)
			);
			setNumberOfVotePages(Math.ceil(level?.rates?.length / limit));
			return setVoteAuthors(level?.rates);
		}

		if (prevRatepage === ratepage || !level?.rates)
			return setVoteAuthorsChanged(false);

		if (voteLogsModalShow === true && voteAuthorsChanged) {
			setPrevRatepage(ratepage);
			getUserSet({ page: ratepage, levelID: level?._id }).then(
				(response) => {
					if (response?.data) {
						setVoteAuthors(response.data.users);
						setRatesToMap(response.data?.rates);
						setNumberOfVotePages(response.data?.numberOfPages);
					}
				}
			);
		}
	}, [
		userIsStaff,
		voteLogsModalShow,
		voteAuthorsChanged,
		ratepage,
		prevRatepage,
		level?.rates,
		level?._id,
		currentUserData,
	]);

	// modal for showing level rates
	const voteLogsModal = () => {
		return (
			<Modal
				show={voteLogsModalShow}
				onHide={() => setVoteLogsModalShow(false)}
				size={
					voteLogsModalShow
						? currentUserData?.isStaff
							? "lg"
							: undefined
						: "sm"
				}
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span className="modal-fill">
					<Modal.Body
						className="center-textoutput"
						style={{
							overflowY: "auto",
							fontSize: "20px",
							maxHeight: "80vh",
						}}>
						{voteLogsModalShow ? (
							<>
								<h1
									style={{ textAlign: "center" }}
									className="yellow">
									Vote logs
								</h1>
								<hr style={{ marginBottom: "0px" }} />
								{(ratesToMap?.length &&
									voteAuthors?.length &&
									level?.rates) ||
								(!currentUserData?.isStaff &&
									level?.rates &&
									ratesToMap?.length) ? (
									ratesToMap.map((rate, key) => (
										<div key={key} className="row">
											<MappedRates
												index={
													key + limit * (ratepage - 1)
												}
												rate={rate}
												content={level}
												authors={voteAuthors}
												levelID={level?._id}
												setLevel={setLevel}
												setRatesToMap={setRatesToMap}
											/>
										</div>
									))
								) : !voteAuthors?.length &&
								  currentUserData?.isStaff &&
								  level?.rates?.length ? (
									<div style={{ marginTop: "16px" }}>
										Querying authors...
									</div>
								) : (
									<div style={{ marginTop: "16px" }}>
										This level hasn't been rated yet.
									</div>
								)}
								<br />
								{level?.rates?.length ? (
									<Pagination
										type="rates"
										numberOfPages={numberOfVotePages}
										ratepage={ratepage}
										setContentChanged={
											setVoteAuthorsChanged
										}
									/>
								) : null}
							</>
						) : (
							<h4>Closing...</h4>
						)}
					</Modal.Body>
					<Modal.Footer className="mx-auto">
						<Button
							style={{ margin: "0 auto" }}
							onClick={() => setVoteLogsModalShow(false)}>
							Close
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// function for backend call
	const handleVideoUpdate = (e) => {
		e.preventDefault();
		setVideoModalShow(false);
		toast.info("Updating level video...");
		// backend call
		updateLevelVideo({
			id: level?._id,
			video: videoUrl,
		}).then((response) =>
			setLevel((prev) => {
				return { ...prev, video: response?.data?.video };
			})
		);
	};

	// add a video url on the level page itself
	const [videoModalShow, setVideoModalShow] = useState<boolean>(false);
	const [videoUrl, setVideoUrl] = useState<string>("");
	const videoUrlModal = () => (
		<Modal
			show={videoModalShow}
			onHide={() => setVideoModalShow(false)}
			aria-labelledby="contained-modal-title-vcenter"
			className="popup-modal"
			centered>
			<span className="modal-fill">
				{videoModalShow ? (
					<>
						<Modal.Body>
							<h3 style={{ textAlign: "center" }}>
								Set video URL
							</h3>
							<hr />
							<input
								type="text"
								style={{ width: "100%", margin: "0 auto" }}
								className="form-control"
								value={videoUrl}
								onChange={(e) => setVideoUrl(e?.target?.value)}
							/>
						</Modal.Body>
						<Modal.Footer className="mx-auto modal-buttons">
							<button
								className="btn btn-primary__special"
								onClick={handleVideoUpdate}>
								Save
							</button>
							<button
								className="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									setVideoModalShow(false);
								}}>
								Close
							</button>
						</Modal.Footer>
					</>
				) : (
					<h3 style={{ textAlign: "center" }}>Closing...</h3>
				)}
			</span>
		</Modal>
	);

	// ---------- //
	// JSX BEGINS //
	// ---------- //

	// if there is no level found, say so
	return !level?._id || !levelAuthor?._id ? (
		// loading spinner
		<div className="container mt-4">
			<div className="row mx-auto">
				{!levelMessage && (
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: "25vh", marginBottom: "25vh" }}
						id="loading-spinner">
						<Spinner animation="grow" variant="primary" />
					</div>
				)}

				{/* display "no level found" message if level is not found */}
				{levelMessage && !level?._id && (
					<div className="col-12">
						<div
							className="card"
							id="infoCard"
							style={{
								textAlign: "center",
								marginTop: "20vh",
								marginBottom: "20vh",
							}}>
							<div className="card-body" id="infoCard">
								<h2 className="card-title">{levelMessage}</h2>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	) : /* if level is private, only display level to author and staff,
	 * display all levels to staff (including deleted levels), or display
	 * regardless if it is public or featured. */
	(level?.status === PRIVATE_LEVEL &&
			//? user should either be level author, a friend or someone with access depending on shareState.
			(userIsLevelAuthor ||
				(currentUserData?._id &&
					level?.shareState === LINK_LEVEL_ACCESS) ||
				(level?.access?.includes(currentUserData?._id) &&
					level?.shareState === INVITE_LEVEL_ACCESS) ||
				(currentUserData?.friends?.includes(levelAuthor?._id) &&
					level?.shareState === FRIENDS_LEVEL_ACCESS))) ||
	  // check for staff OR public/featured level
	  ([PRIVATE_LEVEL, DELETED_LEVEL].includes(level?.status) && userIsStaff) ||
	  [PUBLIC_LEVEL, FEATURED_LEVEL].includes(level?.status) ? (
		// otherwise display level information
		<div
			className={`container mt-4 ${transition ? "unclickable" : ""}`}
			style={{
				opacity: transition ? "0" : "1",
				transition: transitionTime,
				visibility: transitionNormal === true ? "hidden" : "visible",
			}}>
			{showCard === true ? (
				<span className="decimate-overflow">
					<HoverUserCard
						user={levelAuthor}
						cardPosition={cardPosition}
						setShowCard={setShowCard}
						setCardPosition={setCardPosition}
						gameProperties={props.gameProperties}
					/>
				</span>
			) : null}
			<div className="row d-flex justify-content-center">
				<div className="col-lg-9">
					{/* Level title */}
					<nav id="levelHeader">
						<h1
							className="title-card"
							style={{ position: "relative" }}>
							<OverlayTrigger
								overlay={
									<Tooltip id="game">{game?.name}</Tooltip>
								}
								placement="top">
								<img
									alt="Game Logo"
									src={game?.logo}
									style={{
										marginTop: "3px",
										borderRadius: "50%",
										borderWidth: "3px",
										borderStyle: "solid",
										borderColor: "white",
									}}
									width="50"
									height="50"
								/>
							</OverlayTrigger>
							<span
								className="align-middle level-title"
								style={{ paddingLeft: "10px" }}
								title={level?.name}>
								{level?.name}
							</span>
						</h1>
					</nav>
					{/* In this div we're inside the card that contains the thumbnail, star rating, play count, etc. */}
					<div className="card no-top-radius stretch" id="levelInfo">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-auto">
									<div
										className="thumbnail"
										style={{
											backgroundImage: `url(${thumbnail})`,
										}}>
										<div className="thumbnail__video">
											{userIsStaff ||
											currentUserData?.subRoles?.includes(
												SCREENSAVER_SUBROLE
											) ||
											userIsLevelAuthor ? (
												<a
													href="#!"
													onClick={(e) => {
														e.preventDefault();
														setVideoModalShow(true);
													}}>
													<span className="material-symbols-outlined thumbnail__video__edit hov-opacity">
														youtube_activity
													</span>
												</a>
											) : null}
											{level?.video ? (
												<a
													href={
														"https://youtu.be/" +
														level.video
													}
													target="_blank"
													rel="noreferrer">
													<span className="material-icons thumbnail__video__display hov-opacity">
														smart_display
													</span>
												</a>
											) : null}
										</div>
									</div>
								</div>
								<div
									className="col-sm"
									style={{
										position: "relative",
										top: "-16px",
									}}>
									{/* print all full stars for level rating */}
									<MapStars
										level={level}
										rate={null}
										width="14mm"
										height="20mm"
										top="8px"
										color={
											parseFloat(level?.rating) >= 4.9 &&
											level?.rates?.length > 5
												? "#2cd40f"
												: ""
										}
									/>
									<br className="unselectable" />
									<br className="unselectable" />
									<div
										className="icon-spacing"
										style={{ wordBreak: "break-word" }}>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="difficulty"
														style={{
															opacity: 0.96,
														}}>
														Difficulty
													</Tooltip>
												}>
												<span className="material-icons">
													extension
												</span>
											</OverlayTrigger>
											<span
												className="align-top"
												// @ts-ignore
												position="fixed"
												style={{ paddingLeft: "7px" }}>
												{level?.difficulty}
											</span>
										</Nav>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="levelPlayed"
														style={{
															opacity: 0.96,
														}}>
														{currentUserData?._id &&
														level?.hasPlayed
															? "You played this level"
															: currentUserData?._id &&
															  !level?.hasPlayed
															? "Not played yet by you"
															: "Plays"}
													</Tooltip>
												}>
												<span className="material-icons">
													play_arrow
												</span>
											</OverlayTrigger>
											<span
												style={{ paddingLeft: "7px" }}>
												{level?.plays}
											</span>
										</Nav>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="rates"
														style={{
															opacity: 0.96,
														}}>
														Rates
													</Tooltip>
												}>
												<span className="material-icons">
													star
												</span>
											</OverlayTrigger>
											<span
												className="align-top"
												style={{ paddingLeft: "7px" }}>
												{level?.rates?.length}
											</span>
											<button
												className="btn btn-primary"
												onClick={(e) => {
													e.preventDefault();
													setVoteLogsModalShow(true);
												}}
												style={{
													height: "24px",
													marginTop: "1px",
													marginLeft: "10px",
												}}>
												<span
													style={{
														position: "relative",
														bottom: "7px",
													}}>
													view
												</span>
											</button>
										</Nav>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="levelPlayed"
														style={{
															opacity: 0.96,
														}}>
														Favourites
													</Tooltip>
												}>
												<span className="material-icons">
													favorite
												</span>
											</OverlayTrigger>
											<span
												style={{ paddingLeft: "7px" }}>
												{level?.favourites || 0}
											</span>
										</Nav>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="publishDate"
														style={{
															opacity: 0.96,
														}}>
														Publish Date
													</Tooltip>
												}>
												<span className="material-icons">
													publish
												</span>
											</OverlayTrigger>
											<span
												className="align-top"
												style={{ paddingLeft: "7px" }}>
												{moment(level?.postDate).format(
													`MMM D, YYYY - h:mm A`
												)}
											</span>
										</Nav>
										<Nav>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="contributors"
														style={{
															opacity: 0.96,
														}}>
														Contributors
													</Tooltip>
												}>
												<span
													className="material-icons"
													style={{
														width: "20px!important",
													}}>
													groups
												</span>
											</OverlayTrigger>
											<div
												className="align-top col"
												style={{
													marginLeft: "7px",
													lineHeight: "22px",
													marginRight: "16px",
												}}>
												{level?.contributors?.length !==
												0 ? (
													level?.contributors?.join(
														", "
													)
												) : (
													<i>None</i>
												)}
											</div>
										</Nav>
										<Nav>
											<span
												style={{
													display: "inline-flex",
													alignItems: "center",
													gap: "7px",
													flexDirection: "row",
												}}
												className={`${
													level?.status ===
													FEATURED_LEVEL
														? "yellow"
														: ""
												}`}>
												<OverlayTrigger
													// @ts-ignore
													animation={true}
													fade={true}
													delay={{
														show: 50,
														hide: 20,
													}}
													placement="top"
													overlay={
														<Tooltip
															id="visibility"
															style={{
																opacity: 0.96,
															}}>
															Visibility
														</Tooltip>
													}>
													<span className="material-icons">
														{featuredLevel
															? "auto_awesome"
															: deletedLevel
															? "delete"
															: privateLevel
															? "lock"
															: "public"}
													</span>
												</OverlayTrigger>
												<div>{level?.status}</div>
												{/* explain experience for featured levels */}
												{currentUserData?._id !==
													levelAuthor?._id &&
													level?.status ===
														FEATURED_LEVEL && (
														<OverlayTrigger
															overlay={
																<Tooltip id="featuredBonus">
																	Playing,
																	rating and
																	commenting
																	on this
																	level grants
																	40%
																	additional
																	base
																	experience.
																</Tooltip>
															}>
															<span
																className="material-icons"
																style={{
																	display:
																		"flex",
																	alignItems:
																		"center",
																	justifyContent:
																		"center",
																}}>
																help
															</span>
														</OverlayTrigger>
													)}
												{/* show staff if level was previously featured */}
												{userIsStaff &&
													level?.status !==
														FEATURED_LEVEL &&
													level?.previouslyFeatured && (
														<OverlayTrigger
															overlay={
																<Tooltip id="previouslyFeatured">
																	This level
																	was
																	previously
																	featured
																</Tooltip>
															}>
															<span
																className="material-icons"
																style={{
																	display:
																		"flex",
																	alignItems:
																		"center",
																	justifyContent:
																		"center",
																}}>
																info
															</span>
														</OverlayTrigger>
													)}
												{returnShareState()}
											</span>
										</Nav>
										<Nav>
											<span
												style={{
													display: "inline-flex",
													alignItems: "center",
												}}>
												<OverlayTrigger
													// @ts-ignore
													animation={true}
													fade={true}
													delay={{
														show: 50,
														hide: 20,
													}}
													placement="top"
													overlay={
														<Tooltip
															id="tags"
															style={{
																opacity: 0.96,
															}}>
															Tags
														</Tooltip>
													}>
													<span
														className="material-icons"
														style={{
															marginRight: "8px",
															position:
																"relative",
															top: "2px",
															height: "100%",
														}}>
														local_offer
													</span>
												</OverlayTrigger>
												<span
													className="gray tag-container"
													style={{
														whiteSpace: "pre-wrap",
													}}>
													{level &&
													level.tags &&
													level.tags.length > 0 &&
													level.tags[0].trim() !==
														"" ? (
														<>
															{level.tags?.map(
																(
																	tag,
																	index
																) => (
																	<React.Fragment
																		key={
																			index
																		}>
																		<span className="tags">
																			{
																				tag
																			}
																		</span>
																		{index <
																			level
																				.tags
																				.length -
																				1 && (
																			<span>
																				{" "}
																			</span>
																		)}
																	</React.Fragment>
																)
															)}
														</>
													) : (
														<i>No tags</i>
													)}
												</span>
											</span>
										</Nav>
									</div>
								</div>
							</div>
							<div className="row">
								<div
									className="card-title"
									style={{
										textAlign: "right",
										fontSize: "20px",
									}}>
									<a
										href={`/users/${levelAuthor?._id}`}
										onMouseEnter={(e) =>
											handleHover(e, true)
										}
										onMouseLeave={(e) =>
											handleHover(e, false)
										}
										onClick={(e) => handleAuthorClick(e)}
										style={{ paddingRight: "10px" }}>
										{levelAuthor?.username}&nbsp;
										<span
											className="icon-avatar"
											style={{
												borderColor:
													levelAuthor?.icon_outline,
												boxShadow:
													levelAuthor?.icon_glow
														? `0 0 3px 1px ${levelAuthor?.icon_glow}`
														: "none",
											}}>
											<img
												alt="Author Avatar"
												className="icon-avatar__image"
												onError={(e) => {
													// @ts-ignore
													e.target.src =
														props.images.defaultAvatar;
												}}
												src={
													levelAuthor?.avatar
														? levelAuthor?.avatar
														: props.images
																.defaultAvatar
												}
											/>
										</span>{" "}
									</a>
								</div>
							</div>
							<br />
							<br />
							<h5
								className="card-subtitle mb-2 stretch"
								style={{ textAlign: "center" }}>
								<b>
									<u>Description</u>
								</b>
								<br /> <br />
								<div
									style={{
										textAlign:
											level &&
											level.description &&
											level.description.length > 0
												? "left"
												: "center",
									}}>
									<div className="center-textoutput">
										{level?.description?.length > 0 ? (
											// remove invalid html tags from description and display it

											<ReadMore
												content={level?.description}
												height={500}
											/>
										) : (
											<div className="gray">
												<i>
													<br />
													<br />
													<br />
													This level has no
													description set.
												</i>
											</div>
										)}
									</div>
								</div>
							</h5>
						</div>
					</div>
				</div>
				<div className="col-lg-3" style={{ height: "120%" }}>
					{/* large screens */}
					<div
						className="card d-none d-lg-block hidden"
						id="buttonCard">
						<div className="card-body " id="buttonCard">
							{gameID && (
								<div
									className="d-flex visible"
									id="levelButton">
									<button
										className={`btn btn-primary__special level-page-button ${
											transition ? "unclickable" : ""
										}`}
										style={{
											visibility:
												transitionNormal === true
													? "hidden"
													: "visible",
										}}
										onClick={() => {
											setRolledRandomLevel(true);
											toast.info(
												"Rolling more levels..."
											);
										}}>
										<span className="align-middle">
											<span className="material-icons iconjust">
												casino
											</span>
											<span className="level-page-button__text">
												Roll more
											</span>
										</span>
									</button>
								</div>
							)}

							<div className="d-flex visible" id="levelButton">
								<button
									className={`btn btn-primary level-page-button ${
										transition ? "unclickable" : ""
									}`}
									title={
										[0, 1].includes(level?.game)
											? "Copy the level code and open the game"
											: [2, 3].includes(level?.game)
											? "Copy the level code"
											: [4].includes(level?.game)
											? "Download the game file"
											: ""
									}
									style={{
										visibility:
											transitionNormal === true
												? "hidden"
												: "visible",
									}}
									onClick={(e) => handlePlayButtonClick(e)}>
									<span className="align-middle">
										<span className="material-icons iconjust">
											sports_esports
										</span>
										<span className="level-page-button__text">
											Play
										</span>
									</span>
								</button>
							</div>

							{(level?.game === 0 || level?.game === 1) &&
								level?.code?.length && (
									<div
										className="d-flex visible"
										id="levelButton">
										<button
											className={`btn btn-primary level-page-button ${
												transition ? "unclickable" : ""
											}`}
											style={{
												visibility:
													transitionNormal === true
														? "hidden"
														: "visible",
											}}
											onClick={() =>
												setLevelCodeModalShow(true)
											}>
											<span className="align-middle">
												<span className="material-icons iconjust">
													data_object
												</span>
												<span className="level-page-button__text">
													Code
												</span>
											</span>
										</button>
									</div>
								)}

							{/* only display buttons to edit and delete a level if a user is logged in and is the level's author, OR if the user is a moderator/admin */}
							<>
								{(userIsLevelAuthor || userIsStaff) && (
									<div
										className="d-flex visible"
										id="levelButton">
										<button
											className={`btn btn-primary level-page-button  ${
												transition ? "unclickable" : ""
											}`}
											style={{
												visibility:
													transitionNormal === true
														? "hidden"
														: "visible",
											}}
											onClick={() =>
												history.push(
													`/levels/edit/${level?._id}`
												)
											}>
											<span className="align-middle">
												<span className="material-icons iconjust">
													edit
												</span>
												<span className="level-page-button__text">
													Edit
												</span>
											</span>
										</button>
									</div>
								)}

								<div
									className="d-flex visible"
									id="levelButton">
									<button
										className={`btn btn-primary__special level-page-button  ${
											transition ? "unclickable" : ""
										}`}
										style={{
											visibility:
												transitionNormal === true
													? "hidden"
													: "visible",
										}}
										onClick={() => handleFavouriteLevel()}>
										<span className="align-middle">
											<span className="material-icons iconjust">
												{!currentUserData?.favorite_levels?.includes(
													level?._id
												)
													? "favorite"
													: "heart_broken"}
											</span>
											<span className="level-page-button__text">
												{!currentUserData?.favorite_levels?.includes(
													level?._id
												)
													? "Favourite"
													: "Unfavourite"}
											</span>
										</span>
									</button>
								</div>

								{(userIsLevelAuthor || userIsStaff) &&
									level?.status === PRIVATE_LEVEL &&
									currentUserData?.verified === true && (
										<div
											className="d-flex visible"
											id="levelButton">
											<button
												className={`btn btn-primary__special level-page-button  ${
													transition
														? "unclickable"
														: ""
												}`}
												style={{
													visibility:
														transitionNormal ===
														true
															? "hidden"
															: "visible",
												}}
												onClick={() =>
													setAccessManageModalShow(
														true
													)
												}>
												<span className="align-middle">
													<span className="material-icons iconjust">
														group_add
													</span>
													<span className="level-page-button__text">
														Manage
													</span>
												</span>
											</button>
										</div>
									)}

								{/* Report button - only display when the user is logged in (doesn't display on the user's own levels) */}
								{currentUserData?._id &&
									verifiedUser &&
									!deletedLevel &&
									((!privateLevel && userIsLevelAuthor) ||
										!userIsLevelAuthor) && (
										<>
											<div
												className="d-flex visible"
												id="levelButton">
												<button
													className={`btn btn-primary__danger level-page-button  ${
														transition
															? "unclickable"
															: ""
													}`}
													style={{
														visibility:
															transitionNormal ===
															true
																? "hidden"
																: "visible",
													}}
													title={
														userIsLevelAuthor
															? "Request mod reviewal of malicious comments/rates"
															: "Report abuse"
													}
													onClick={() =>
														(window.location.href = `/report?id=${level?._id}&type=level`)
													}>
													<span className="align-middle">
														<span className="material-icons iconjust">
															report
														</span>
														<span className="level-page-button__text">
															{userIsLevelAuthor
																? "Report rates"
																: "Report"}
														</span>
													</span>
												</button>
											</div>
										</>
									)}

								{/* Delete/Restore button - allows the deletion or restoration of a level by an admin. */}
								{(userIsStaff || userIsLevelAuthor) && (
									<div
										className="d-flex visible"
										id="levelButton">
										<button
											className={`btn btn-primary${
												deletedLevel
													? "__special"
													: "__danger"
											} level-page-button ${
												transition ? "unclickable" : ""
											}`}
											// @ts-ignore
											href="#!"
											style={{
												visibility:
													transitionNormal === true
														? "hidden"
														: "visible",
											}}
											onClick={() =>
												setDeleteModalShow(true)
											}>
											<span className="align-middle">
												<span className="material-icons iconjust">
													{deletedLevel
														? "restore"
														: "delete"}
												</span>
												<span className="level-page-button__text">
													{deletedLevel
														? "Restore"
														: "Delete"}
												</span>
											</span>
										</button>
									</div>
								)}
							</>

							{/* Feature button - only display when the user is a staff member AND isn't the level author */}
							{[PUBLIC_LEVEL, FEATURED_LEVEL].includes(
								level?.status
							) &&
								((currentUserData?._id &&
									userIsStaff &&
									!userIsLevelAuthor) ||
									userIsAdmin) && (
									<>
										<div
											className="d-flex visible"
											id="levelButton">
											<button
												className={`btn btn-primary__special level-page-button  ${
													transition
														? "unclickable"
														: ""
												}`}
												style={{
													visibility:
														transitionNormal ===
														true
															? "hidden"
															: "visible",
												}}
												onClick={() =>
													setFeatureModalShow(true)
												}>
												<span className="align-middle">
													<span>
														<span className="material-icons iconjust">
															star
														</span>
													</span>
													<span className="level-page-button__text">
														{" "}
														{featuredLevel
															? "Unfeature"
															: "Feature"}
													</span>
												</span>
											</button>
										</div>
									</>
								)}

							{/* Obliterate button - permanently deletes a level, and is only accessible by admins. */}
							{[DELETED_LEVEL].includes(level?.status) &&
								currentUserData?._id &&
								userIsAdmin && (
									<>
										<div
											className="d-flex visible"
											id="levelButton">
											<button
												className={`btn btn-primary__danger level-page-button  ${
													transition
														? "unclickable"
														: ""
												}`}
												style={{
													visibility:
														transitionNormal ===
														true
															? "hidden"
															: "visible",
												}}
												onClick={() => {
													toast.warn(
														`This will permanently delete the level from the database, click here to confirm.`,
														{
															onClick: () => {
																setLevelObliteration(
																	true
																);
															},
														}
													);
												}}>
												<span className="align-middle">
													<span>
														<span className="material-icons iconjust">
															local_fire_department
														</span>
													</span>
													<span className="level-page-button__text">
														Obliterate
													</span>
												</span>
											</button>
										</div>
									</>
								)}
						</div>
					</div>
					<br />
					{/* mobile screens */}
					<div className="card d-lg-none" id="buttonCard">
						<div
							className="card-body d-flex justify-content-center"
							id="buttonCard">
							<div
								className="d-flex justify-content-center flex-wrap"
								id="levelButtons">
								{gameID && (
									<div
										className="d-flex justify-content-center"
										id="levelButton">
										<OverlayTrigger
											overlay={
												<Tooltip
													id="roleMore"
													className="d-lg-none">
													Roll more
												</Tooltip>
											}
											placement="top">
											<a
												href="#!"
												onClick={() =>
													setRolledRandomLevel(true)
												}>
												<span
													className="material-icons"
													id="actionButton">
													casino
												</span>
											</a>
										</OverlayTrigger>
									</div>
								)}

								<div
									className="d-flex justify-content-center"
									style={{
										paddingLeft: gameID ? "20px" : "0px",
									}}
									id="levelButton">
									<OverlayTrigger
										overlay={
											<Tooltip
												id="copyAndPlay"
												className="d-lg-none">
												Play
											</Tooltip>
										}
										placement="top">
										<a
											href="#!"
											onClick={(e) =>
												handlePlayButtonClick(e)
											}>
											<span
												className="material-icons"
												id="actionButton">
												sports_esports
											</span>
										</a>
									</OverlayTrigger>
								</div>

								{(level?.game === 0 || level?.game === 1) &&
									level?.code?.length && (
										<div
											className="d-flex justify-content-center"
											style={{ paddingLeft: "20px" }}
											id="levelButton">
											<OverlayTrigger
												overlay={
													<Tooltip
														id="code"
														className="d-lg-none">
														Code
													</Tooltip>
												}
												placement="top">
												<a
													href="#!"
													onClick={() =>
														setLevelCodeModalShow(
															true
														)
													}>
													<span
														className="material-icons"
														id="actionButton">
														data_object
													</span>
												</a>
											</OverlayTrigger>
										</div>
									)}

								{/* only display buttons to edit and delete a level if a user is logged in and is the level's author, OR if the user is a moderator/admin */}
								{(userIsLevelAuthor || userIsStaff) && (
									<div
										className="d-flex justify-content-center"
										style={{ paddingLeft: "20px" }}
										id="levelButton">
										<OverlayTrigger
											overlay={
												<Tooltip
													id="edit"
													className="d-lg-none">
													Edit
												</Tooltip>
											}
											placement="top">
											<a
												href={`/levels/edit/${level?._id}`}
												onClick={(e) => {
													e.preventDefault();
													history.push(
														`/levels/edit/${level?._id}`
													);
												}}>
												<span
													className="material-icons"
													id="actionButton">
													edit
												</span>
											</a>
										</OverlayTrigger>
									</div>
								)}

								<div
									className="d-flex justify-content-center"
									style={{
										paddingLeft: "20px",
									}}
									id="levelButton">
									<OverlayTrigger
										overlay={
											<Tooltip
												id="manage"
												className="d-lg-none">
												{!currentUserData?.favorite_levels?.includes(
													level?._id
												)
													? "Favourite"
													: "Unfavourite"}
											</Tooltip>
										}
										placement="top">
										<a
											href="#!"
											onClick={() =>
												handleFavouriteLevel()
											}>
											<span
												className="material-icons"
												id="actionButton">
												{!currentUserData?.favorite_levels?.includes(
													level?._id
												)
													? "favorite"
													: "heart_broken"}
											</span>
										</a>
									</OverlayTrigger>
								</div>

								{(userIsLevelAuthor || userIsStaff) &&
									level?.status === PRIVATE_LEVEL &&
									currentUserData?.verified === true && (
										<>
											<div
												className="d-flex justify-content-center"
												style={{
													paddingLeft: "20px",
												}}
												id="levelButton">
												<OverlayTrigger
													overlay={
														<Tooltip
															id="manage"
															className="d-lg-none">
															Manage
														</Tooltip>
													}
													placement="top">
													<a
														href="#!"
														onClick={() =>
															setAccessManageModalShow(
																true
															)
														}>
														<span
															className="material-icons"
															id="actionButton">
															group_add
														</span>
													</a>
												</OverlayTrigger>
											</div>
										</>
									)}

								{/* Report button - only display when the user is logged in (doesn't display on the user's own levels) */}
								{currentUserData?._id &&
									verifiedUser &&
									!deletedLevel &&
									((!privateLevel && userIsLevelAuthor) ||
										!userIsLevelAuthor) && (
										<>
											<div
												className="d-flex justify-content-center"
												style={{
													paddingLeft: "20px",
												}}
												id="levelButton">
												<OverlayTrigger
													overlay={
														<Tooltip
															id="(self-)report"
															className="d-lg-none">
															{userIsLevelAuthor
																? "Report rates"
																: "Report"}
														</Tooltip>
													}
													placement="top">
													<a
														href={`/report?id=${level?._id}&type=level`}
														onClick={(e) => {
															e.preventDefault();
															history.push(
																`/report?id=${level?._id}&type=level`
															);
														}}>
														<span
															className="material-icons"
															id="actionButton">
															report
														</span>
													</a>
												</OverlayTrigger>
											</div>
										</>
									)}

								{/* Delete/Restore button - allows the deletion or restoration of a level by an admin. */}
								{(userIsStaff || userIsLevelAuthor) && (
									<div
										className="d-flex justify-content-center"
										style={{ paddingLeft: "20px" }}
										id="levelButton">
										<OverlayTrigger
											overlay={
												<Tooltip
													id="restoreOrDelete"
													className="d-lg-none">
													{deletedLevel && userIsStaff
														? "Restore"
														: "Delete"}
												</Tooltip>
											}
											placement="top">
											<a
												href="#!"
												onClick={() =>
													setDeleteModalShow(true)
												}>
												<span
													className="material-icons"
													id="actionButton">
													{deletedLevel
														? "restore"
														: "delete"}
												</span>
											</a>
										</OverlayTrigger>
									</div>
								)}

								{/* Feature button - only display on public levels when the user is a staff member AND isn't the level author */}
								{[PUBLIC_LEVEL, FEATURED_LEVEL].includes(
									level?.status
								) &&
									((currentUserData?._id &&
										userIsStaff &&
										!userIsLevelAuthor) ||
										userIsAdmin) && (
										<>
											<div
												className="d-flex justify-content-center"
												style={{ paddingLeft: "20px" }}
												id="levelButton">
												<OverlayTrigger
													overlay={
														<Tooltip
															id="(un)feature"
															className="d-lg-none">
															{featuredLevel
																? "Unfeature"
																: "Feature"}
														</Tooltip>
													}
													placement="top">
													<a
														href="#!"
														onClick={() =>
															setFeatureModalShow(
																true
															)
														}>
														<span
															className="material-icons"
															id="actionButton">
															star
														</span>
													</a>
												</OverlayTrigger>
											</div>
										</>
									)}

								{/* Obliterate button - permanently deletes a level, and is only accessible by admins. */}
								{[DELETED_LEVEL].includes(level?.status) &&
									currentUserData?._id &&
									userIsAdmin && (
										<>
											<div
												className="d-flex justify-content-center"
												style={{ paddingLeft: "20px" }}
												id="levelButton">
												<OverlayTrigger
													overlay={
														<Tooltip
															id="obliterate"
															className="d-lg-none">
															Obliterate
														</Tooltip>
													}
													placement="top">
													<a
														href="#!"
														onClick={() => {
															toast.warn(
																`This will permanently delete the level from the database, click here to confirm.`,
																{
																	onClick:
																		() => {
																			setLevelObliteration(
																				true
																			);
																		},
																}
															);
														}}>
														<span
															className="material-icons"
															id="actionButton">
															local_fire_department
														</span>
													</a>
												</OverlayTrigger>
											</div>
										</>
									)}
							</div>
						</div>
					</div>
					{levelCodeModal()}
					{deleteModal()}
					{featureModal()}
					{accessManageModal()}
					{voteLogsModal()}
					{randomLevelModal()}
					{ratingModal()}
					{videoUrlModal()}
					<br className="d-lg-none" />
					{/* only display rating info if user is logged in, is not the level's author, and has not rated yet. */}
					{canRateLevel && !deletedLevel && !privateLevel && (
						<div className="card cardjustS">
							<div className="card-header">
								<img
									src={props.images.Exclamation}
									alt="Exclamation"
									width="28"
									height="28"
								/>
								<span
									className="align-middle"
									style={{
										paddingLeft: "7px",
										fontSize: "17px",
									}}>
									{level?.hasRated === 0
										? "Rate this level"
										: "Change your rating"}
								</span>
							</div>
							<div className="card-body d-flex justify-content-center">
								<form
									style={{
										position: "relative",
										top: "-10px",
									}}>
									{starRate === 5 ? (
										<span>
											<ReactStars
												classNames="unselectable"
												count={5}
												isHalf={true}
												value={5}
												onChange={(newRating) => {
													handleStarRating(newRating);
												}}
												size={8}
												activeColor="rgb(240, 185, 0)"
												edit={true}
												emptyIcon={
													<StarEmpty
														width="13mm"
														height="18mm"
													/>
												}
												halfIcon={
													<StarHalf
														width="13mm"
														height="18mm"
														color="#2cd40f"
													/>
												}
												filledIcon={
													<StarFull
														width="13mm"
														height="18mm"
														color="#2cd40f"
													/>
												}
											/>
										</span>
									) : (
										<div>
											<ReactStars
												classNames="unselectable"
												count={5}
												isHalf={true}
												value={starRate}
												onChange={(newRating) => {
													handleStarRating(newRating);
												}}
												size={8}
												activeColor="rgb(240, 185, 0)"
												edit={true}
												emptyIcon={
													<StarEmpty
														width="13mm"
														height="18mm"
													/>
												}
												halfIcon={
													<StarHalf
														width="13mm"
														height="18mm"
														color=""
													/>
												}
												filledIcon={
													<StarFull
														width="13mm"
														height="18mm"
														color=""
													/>
												}
											/>
										</div>
									)}
								</form>
							</div>
						</div>
					)}
					{/* Box displaying you need to verify */}
					{!canRateLevel && currentUserData && !userIsLevelAuthor && (
						<div className="card">
							<div
								className="card-body"
								style={{ borderRadius: "inherit" }}>
								{/* @ts-ignore */}
								<center>
									{verifiedUser === false && (
										<span
											className="align-middle"
											style={{ paddingLeft: "7px" }}>
											<span className="red">
												You can't rate/report levels
												until you have{" "}
												<a
													href={"/verify"}
													onClick={(e) => {
														e.preventDefault();
														history.push("/verify");
													}}>
													verified
												</a>{" "}
												your account.
											</span>
										</span>
									)}
									{/* @ts-ignore */}
								</center>
							</div>
						</div>
					)}
				</div>

				{/* only display comment info if user is logged in and is not the level's author, and has not commented yet.*/}
				{currentUserData?._id && !cantPostComments && !userIsLevelAuthor
					? currentUserCommentCount < 3 &&
					  !noCommentBox &&
					  ([PUBLIC_LEVEL, FEATURED_LEVEL].includes(level?.status) ||
							currentUserData?.isAdmin ||
							(level?.status === PRIVATE_LEVEL &&
								(level?.shareState === LINK_LEVEL_ACCESS ||
									(level?.shareState ===
										FRIENDS_LEVEL_ACCESS &&
										currentUserData?.friends?.includes(
											levelAuthor?._id
										)) ||
									(level?.shareState ===
										INVITE_LEVEL_ACCESS &&
										level?.access?.includes(
											currentUserData?._id
										))))) && (
							<span className="cardleft cardsize">
								<div className="col-lg-9 cardsize">
									<div className="card cardsize">
										<div className="card-header">
											<img
												src={props.images.Exclamation}
												alt="Exclamation"
												width="32"
												height="32"
											/>
											<span
												className="align-middle"
												style={{
													paddingLeft: "9px",
													fontSize: "20px",
												}}>
												Add a comment to{" "}
												<i>
													<span className="gray">
														{level?.name}
													</span>
												</i>
											</span>
										</div>
										<div className="card-body">
											<form
												onSubmit={handleCommentSubmit}>
												<div className="form-group">
													<label htmlFor="content">
														What are your thoughts?{" "}
														{levelComment?.length ? (
															<span className="yellow">
																(
																{
																	levelComment?.length
																}
																/8000)
															</span>
														) : null}
													</label>
													<textarea
														className="form-control"
														style={{
															resize: "none",
															marginLeft: "unset",
															width: "100%",
														}}
														id="content"
														name="content"
														maxLength={8000}
														rows={7}
														placeholder="Leave a comment about what you liked and/or didn't like."
														value={levelComment}
														onChange={(e) => {
															const value =
																e.target.value;
															setLevelComment(
																value
															);
															// set a page store for level comment
															if (value !== "")
																return sessionStorage.setItem(
																	`levelComment${levelID}`,
																	value
																);
															// remove it if empty
															sessionStorage.removeItem(
																`levelComment${levelID}`
															);
														}}
													/>
												</div>
												<br />
												<div>
													<button
														disabled={
															lockCommentSubmit
														}
														type="submit"
														className="btn btn-primary">
														Add comment
													</button>
													{userIsReviewer ||
													currentUserData?.isAdmin ? (
														<span className="comment-highlight-box">
															<input
																className="form-check-input"
																style={{
																	bottom: "-18px",
																	position:
																		"relative",
																	height: "20px",
																	width: "20px",
																}}
																type="checkbox"
																id="myCheckbox"
																name="myCheckbox"
																onChange={(
																	e
																) => {
																	setCommentHighlight(
																		e.target
																			.checked
																	);
																}}
																value="1"
															/>
															<label htmlFor="myCheckbox">
																&nbsp;&nbsp;Highlight
																as review
															</label>
														</span>
													) : null}
												</div>
											</form>
										</div>
									</div>
								</div>
							</span>
					  )
					: // only display if the user is not logged in
					  !currentUserData?._id && (
							<div className="container mt-4">
								{/* tell a user they cannot add rates or comments to a level if they are not signed in */}
								<div className="col-12 col-lg-9">
									<div
										className="card"
										id="infoCard"
										style={{ textAlign: "center" }}>
										<div
											className="card-body"
											id="infoCard">
											<h5 className="card-title">
												Please{" "}
												<a
													href={`/auth?url=${window.location.pathname}`}>
													login or register
												</a>{" "}
												to rate this level or post a
												comment!
											</h5>
										</div>
									</div>
								</div>
							</div>
					  )}
				{currentUserData?._id &&
					cantPostComments &&
					!userIsLevelAuthor && (
						<div className="container mt-4">
							{/* disable commenting for unverified & muted users */}
							<div className="col-12 col-lg-9">
								<div
									className="card"
									id="infoCard"
									style={{ textAlign: "center" }}>
									<div className="card-body" id="infoCard">
										{verifiedUser ? (
											socialSetting > 0 ? (
												socialSetting === 1 ? (
													userIsStaff ||
													userIsAdmin ||
													currentUserData?.subRoles?.includes(
														"Reviewer"
													) ? (
														<h5 className="card-title">
															<span className="yellow">
																This level is
																set to friends
																only, but your
																reviewer subrole
																makes you
																immune!
															</span>
														</h5>
													) : (
														<h5 className="card-title">
															<span className="gray">
																<a
																	href={`/users/${levelAuthor._id}`}
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		history.push(
																			`/users/${levelAuthor._id}`
																		);
																	}}>
																	{
																		levelAuthor?.username
																	}
																</a>
																&nbsp;has
																limited level
																comments to
																friends only!
															</span>
														</h5>
													)
												) : userIsStaff ||
												  currentUserData.subRoles.includes(
														"Reviewer"
												  ) ? (
													<h5 className="card-title">
														<span className="yellow">
															you are
															staff/reviewer, you
															are bypassing the
															disablement of
															comments
														</span>
													</h5>
												) : (
													<h5 className="card-title">
														<span className="gray">
															<a
																href={`/users/${levelAuthor._id}`}
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	history.push(
																		`/users/${levelAuthor._id}`
																	);
																}}>
																{
																	levelAuthor?.username
																}
															</a>
															&nbsp;has disabled
															commenting on their
															levels!
														</span>
													</h5>
												)
											) : userIsMuted ? (
												<h5 className="card-title">
													You cannot comment on levels
													while&nbsp;
													<span className="gray">
														muted
													</span>
													!
												</h5>
											) : (
												<h5 className="card-title">
													Please visit the{" "}
													<a
														href="/report?type=feedback"
														onClick={(e) => {
															e.preventDefault();
															history.push(
																"/report?type=feedback"
															);
														}}>
														feedback page
													</a>{" "}
													if you see this message.
												</h5>
											)
										) : (
											<h5 className="card-title">
												Join the discussion by{" "}
												<a
													href={"/verify"}
													onClick={(e) => {
														e.preventDefault();
														history.push("/verify");
													}}>
													verifying
												</a>{" "}
												your account.
											</h5>
										)}
									</div>
								</div>
							</div>
						</div>
					)}

				{/* level comments */}
				<br />
				{levelComments?.length && !commentsQuerying ? (
					<div style={{ width: "100%", margin: "24px 0 0 40px" }}>
						<button
							className="btn btn-primary__special"
							disabled={commentsQuerying}
							onClick={() => {
								setCommentSort((prev) =>
									prev === "new" ? "priority" : "new"
								);
								history.push(
									window.location.pathname + "?page=1"
								);
								setPreviousPage(0);
								setLevelCommentsChanged(true);
							}}>
							Sorted by {commentSort}
						</button>
					</div>
				) : null}
				<div className="container mt-4 cardsize">
					<div className="row mx-auto">
						{/* display level comments if any are found, (continually updates with new comments) */}
						{levelComments &&
						levelComments?.length &&
						!commentsQuerying &&
						levelComments?.length !== 0 ? (
							<MapLevelComments
								levelComments={levelComments}
								setLevelComments={setLevelComments}
								controlLevelComments={controlLevelComments}
								setControlLevelComments={
									setControlLevelComments
								}
								userIsStaff={userIsStaff}
								userIsAdmin={userIsAdmin}
								defaultAvatar={props.images.defaultAvatar}
								setLevelCommentToDelete={
									setLevelCommentToDelete
								}
								setDeleteCommentModalShow={
									setDeleteCommentModalShow
								}
								deleteLevelCommentModal={
									deleteLevelCommentModal
								}
								page={page}
								threadpage={threadpage}
								prevThreadPage={prevThreadPage}
								setPrevThreadPage={setPrevThreadPage}
								level={level}
								images={props.images}
								cantPostComments={cantPostComments}
								setLevelCommentsChanged={
									setLevelCommentsChanged
								}
								levelAuthor={levelAuthor}
								query={query}
							/>
						) : level?._id && commentsQuerying ? (
							<div
								className="d-flex justify-content-center"
								style={{ width: "75%" }}>
								<Spinner animation="grow" variant="primary" />
								<br />
								<br />
								<br />
							</div>
						) : null}
						{levelComments?.length !== 0 && (
							<div className="MuiPagination-root col-12 col-lg-9 cardsize">
								<Pagination
									setContentChanged={setLevelCommentsChanged}
									page={page}
									numberOfPages={numberOfPages}
									type="comment"
									siblingCount={3}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	) : (
		// don't display private levels to users who are not the level's author,
		// or deleted levels to users who aren't staff.
		<div className="container mt-4">
			{/* display respective message if level is either private or deleted */}
			<div className="col-12">
				<div
					className="card"
					id="infoCard"
					style={{ textAlign: "center" }}>
					<div className="card-body" id="infoCard">
						<h2 className="card-title">
							This level{" "}
							{level?.status === PRIVATE_LEVEL && "is private!"}
							{level?.status === DELETED_LEVEL &&
								"has been deleted!"}
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

const MapLevelComments = ({
	levelComments,
	setLevelComments,
	controlLevelComments,
	setControlLevelComments,
	userIsStaff,
	userIsAdmin,
	defaultAvatar,
	setLevelCommentToDelete,
	setDeleteCommentModalShow,
	deleteLevelCommentModal,
	page,
	threadpage,
	prevThreadPage,
	setPrevThreadPage,
	level,
	images,
	cantPostComments,
	setLevelCommentsChanged,
	levelAuthor,
	query,
}) => {
	const history = useHistory();
	const currentUserData = useContext<any>(UserContext);

	// state selectors
	const [commentThreadModalShow, setCommentThreadModalShow] =
		useState<boolean>(false);
	const [commentReply, setCommentReply] = useState<string>("");
	const [lockReplySubmit, setLockReplySubmit] = useState<boolean>(false);

	const [repliesThreadChanged, setRepliesThreadChanged] =
		useState<boolean>(false);
	const [threadReplies, setThreadReplies] = useState<any[]>([]);
	const [controlThreadReplies, setControlThreadReplies] = useState<any[]>([]);
	const [numberOfThreadPages, setNumberOfThreadPages] = useState<number>(0);

	const [showReplies, setShowReplies] = useState<boolean>(false);

	const [targetThread, setTargetThread] = useState<any>("");
	const [deleteCommentReplyModalShow, setDeleteCommentReplyModalShow] =
		useState<boolean>(false);
	const [targetReply, setTargetReply] = useState<any>("");
	const [replyDeleteAction, setReplyDeleteAction] = useState<any>("");

	const [notifReferer, setNotifReferer] = useState<string | null>(null);
	const referer: string | null = query.get("referer") || null;

	// set a state for notif referer
	useEffect(() => {
		if (referer && !notifReferer) setNotifReferer(referer);
	}, [referer, notifReferer]);

	// set a referer for a profile comment modal
	useEffect(() => {
		if (notifReferer) {
			getLevelComment(notifReferer)
				.then((response) => {
					// remove the notifrefer, store the comment as ref and target
					setTargetThread(response?.data?.comment);
					setRepliesThreadChanged(true);
					setCommentThreadModalShow(true);
				})
				.finally(() => {
					history.push(window.location.pathname);
					setNotifReferer(null);
				});
		}
	}, [notifReferer, setNotifReferer, currentUserData, history]);

	// fetch replies belonging to a comment if "targetThread" gets triggered
	useEffect(() => {
		// done execute if nothing changed
		if (prevThreadPage === threadpage)
			return setRepliesThreadChanged(false);

		// make a fetch request...
		if (targetThread !== "" && repliesThreadChanged) {
			const id = level?._id;
			const commentID = targetThread?._id;
			setShowReplies(false);
			setRepliesThreadChanged(false);
			getLevelCommentReplyPage(id, commentID, { threadpage }).then(
				(response) => {
					if (response?.data) {
						if (
							response?.data?.numberOfPages < threadpage &&
							response?.data?.numberOfPages !== 0
						) {
							query.set("threadpage", "1");
							history.push(`?${query.toString()}`);
							return setRepliesThreadChanged(true);
						}
						setThreadReplies(response?.data?.levelCommentReplies);
						setControlThreadReplies(
							response.data.levelCommentReplies
						);
						setPrevThreadPage(threadpage);
						setNumberOfThreadPages(
							response.data.numberOfPages || 1
						);
						if (response.data?.length !== 0) setShowReplies(true);
					}
				}
			);
		}
	}, [
		targetThread,
		commentThreadModalShow,
		repliesThreadChanged,
		threadpage,
		page,
		level?._id,
		showReplies,
		prevThreadPage,
		setPrevThreadPage,
		currentUserData,
		query,
		history,
	]);

	const commentThreadModal = () => {
		// useful constants
		const commentAuthor = targetThread?.author;
		return (
			<Modal
				show={commentThreadModalShow}
				onHide={() => {
					setCommentThreadModalShow(false);
					setPrevThreadPage(-1);
				}}
				className="comment-modal"
				aria-labelledby="contained-modal-title-vcenter"
				size="lg"
				centered>
				<div
					className="modal-fill"
					style={{
						width: "100%",
					}}>
					<Modal.Body className="thread-modal-body">
						<div
							className={`comment ${
								targetThread?.visibility === "public"
									? "public-comment"
									: targetThread?.visibility === "deleted" &&
									  "deleted-comment"
							}__main`}
							style={{
								backgroundColor: "rgb(255,255,255,0.1)",
							}}>
							<div>
								<div>
									<div
										style={{
											position: "absolute",
											top: "6px",
											right: "6px",
										}}>
										{/* display all icons*/}

										{/*(userIsStaff && !authorIsAdmin) ||
										targetThread?.author ===
											currentUserData?._id ||
										userIsAdmin ? (
											// delete comment if public or private
											targetThread.visibility !==
											"deleted" ? (
												<OverlayTrigger
													delay={{
														show: 210,
														hide: 0,
													}}
													placement="top"
													overlay={
														<Tooltip>
															Delete comment
														</Tooltip>
													}>
													<a
														onClick={(e) => {
															setCommentThreadModalShow(
																false
															);
															handlelevelCommentDeleteOrObliterate(
																e,
																"deleteComment",
																targetThread
															);
														}}
														href={"#!"}
														className="material-symbols-outlined thread-icon">
														delete
													</a>
												</OverlayTrigger>
											) : (
												// OBLITERATE comment if admin
												<OverlayTrigger
													delay={{
														show: 210,
														hide: 0,
													}}
													placement="top"
													overlay={
														<Tooltip>
															OBLITERATE
														</Tooltip>
													}>
													<a
														onClick={(e) => {
															setCommentThreadModalShow(
																false
															);
															handlelevelCommentDeleteOrObliterate(
																e,
																"obliterateReply",
																targetThread
															);
														}}
														href={"#!"}
														className={`material-symbols-outlined obliterate-icon ${
															!userIsAdmin &&
															"hidden"
														}`}>
														local_fire_department
													</a>
												</OverlayTrigger>
											)
										) : (
											""
										)*/}
									</div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											borderColor:
												commentAuthor?.icon_outline,
											boxShadow: commentAuthor?.icon_glow
												? `0 0 4px 2px ${commentAuthor?.icon_glow}`
												: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													images?.defaultAvatar;
											}}
											src={
												commentAuthor?.avatar
													? commentAuthor?.avatar
													: images?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={commentAuthor?.username}
											href={`/users/${commentAuthor?._id}`}
											onClick={(e) => {
												e.preventDefault();
												history.push(
													`/users/${commentAuthor?._id}`
												);
											}}>
											{commentAuthor?.username}
										</a>
									</span>
								</div>
								<hr
									style={{
										margin: "4px 7px 20px 7px",
									}}
								/>
								{/* display private or deleted text */}
								{targetThread?.visibility === "deleted" && (
									<span className="comment__deleted-display">
										Deleted
									</span>
								)}
								{/* comment + timestamp */}
								<div className="comment__comment-body">
									<CommentStatus
										levelComments={levelComments}
										levelComment={targetThread}
										level={level}
										setLevelComments={setLevelComments}
									/>
									<ReadMore
										content={targetThread?.content}
										document={targetThread}
										height={410}
									/>
								</div>
								<div className="comment__comment-timestamp">
									Posted on{" "}
									<b>
										{moment(targetThread?.postDate).format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
							</div>
						</div>
						<hr />
						<CommentReplies
							threadReplies={threadReplies}
							setThreadReplies={setThreadReplies}
							controlThreadReplies={controlThreadReplies}
							setControlThreadReplies={setControlThreadReplies}
							userIsStaff={userIsStaff}
							images={images}
							userIsAdmin={userIsAdmin}
							level={level}
							showReplies={showReplies}
							setDeleteCommentModalShow={
								setDeleteCommentModalShow
							}
							setCommentThreadModalShow={
								setCommentThreadModalShow
							}
							targetThread={targetThread}
							setTargetThread={setTargetThread}
							setLevelCommentsChanged={setLevelCommentsChanged}
							deleteCommentReplyModalShow={
								deleteCommentReplyModalShow
							}
							setDeleteCommentReplyModalShow={
								setDeleteCommentReplyModalShow
							}
							deleteLevelCommentReplyModal={
								deleteLevelCommentReplyModal
							}
							setTargetReply={setTargetReply}
							setReplyDeleteAction={setReplyDeleteAction}
						/>
						{threadReplies?.length > 0 && (
							<Pagination
								setContentChanged={setRepliesThreadChanged}
								threadpage={threadpage}
								numberOfThreadPages={numberOfThreadPages}
								showReplies={showReplies}
								type="reply"
							/>
						)}
					</Modal.Body>
					{!cantPostComments ? (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								<textarea
									// @ts-ignore
									type="text"
									className="form form-control thread-form"
									style={{ width: "90%" }}
									placeholder="Here to add to the discussion, hm?"
									maxLength={5000}
									value={commentReply}
									onChange={(e) =>
										setCommentReply(e.target.value)
									}
								/>
								<br />
								<div className="thread-footer">
									<Button
										className="btn-primary__special"
										style={{
											position: "relative",
											left: "0",
										}}
										disabled={lockReplySubmit}
										// send a reply to a comment in the thread
										onClick={() => {
											const levelID = level?._id;
											const commentID = targetThread?._id;

											if (
												currentUserData?.blocked_users?.includes(
													level?.author
												)
											)
												return toast.error(
													"You can't reply to comments on levels of users who you've blocked"
												);
											if (
												levelAuthor?.blocked_users?.includes(
													currentUserData?._id
												)
											)
												return toast.error(
													"You're blocked by the author of this level."
												);

											if (
												currentUserData?.blocked_users?.includes(
													targetThread?.author?._id
												)
											)
												return toast.error(
													"You can't reply to user who you've blocked"
												);

											if (
												commentAuthor?.blocked_users?.includes(
													currentUserData?._id
												)
											)
												return toast.error(
													"You're blocked by the author of this comment."
												);

											if (
												commentReply !== "" &&
												!currentUserData?.mainRoles?.includes(
													MUTED_USER
												)
											) {
												setLockReplySubmit(true);
												createLevelCommentReply(
													levelID,
													commentID,
													{
														content:
															removeExcessiveBreaks(
																commentReply
															),
													}
												)
													.then((response) => {
														setLockReplySubmit(
															false
														);
														if (response) {
															// update threads
															setThreadReplies(
																(
																	threadReplies
																) => [
																	...threadReplies,
																	response
																		.data
																		.newLevelCommentReply,
																]
															);
															setLockReplySubmit(
																false
															);
															setCommentReply("");
															setShowReplies(
																true
															);
															incrementReplyCount(
																targetThread?._id
															);
															// update subscribe button
															if (
																!targetThread?.subscribers?.includes(
																	currentUserData?._id
																)
															) {
																setLevelComments(
																	(
																		prevComments
																	) =>
																		prevComments?.map(
																			(
																				prevComment
																			) => {
																				// select the specific levelcomment
																				if (
																					targetThread?._id ===
																					prevComment?._id
																				) {
																					return {
																						...prevComment,
																						subscribers:
																							[
																								...prevComment?.subscribers,
																								currentUserData?._id,
																							],
																					};
																				} else
																					return prevComment;
																			}
																		)
																);
															}
														}
													})
													.catch(() =>
														setLockReplySubmit(
															false
														)
													);
											} else {
												currentUserData?.mainRoles?.includes(
													MUTED_USER
												)
													? toast.error(
															"You can't post comments while muted!"
													  )
													: toast.error(
															"New comments can't be empty!"
													  );
											}
										}}>
										Send&nbsp;
										{commentReply?.length
											? `(${commentReply?.length})`
											: null}
									</Button>{" "}
									<Button
										disabled={lockReplySubmit}
										onClick={() => {
											const type = "levelComment";
											const force =
												targetThread?.subscribers?.includes(
													currentUserData?._id
												)
													? "unsubscribe"
													: "subscribe";
											setLockReplySubmit(true);
											// backend request
											subscribeToThread(
												targetThread?._id,
												force,
												type
											)
												.then(() => {
													setLockReplySubmit(false);
													setLevelComments(
														(prevComments) =>
															prevComments.map(
																(
																	prevComment
																) => {
																	if (
																		targetThread._id ===
																		prevComment._id
																	) {
																		// Add or remove currentUserData._id based on the value of "force"
																		const updatedSubscribers =
																			force ===
																			"subscribe"
																				? [
																						...(prevComment?.subscribers ||
																							[]),
																						currentUserData._id,
																				  ]
																				: prevComment?.subscribers?.filter(
																						(
																							id
																						) =>
																							id !==
																							currentUserData._id
																				  );
																		return {
																			...(prevComment ||
																				[]),
																			subscribers:
																				updatedSubscribers,
																		};
																	} else {
																		return prevComment;
																	}
																}
															)
													);
												})
												.catch(() =>
													setLockReplySubmit(false)
												);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										{targetThread?.subscribers?.includes(
											currentUserData?._id
										)
											? "Unsubscribe"
											: "Subscribe"}
									</Button>{" "}
									<Button
										onClick={() => {
											setCommentThreadModalShow(false);
											setPrevThreadPage(-1);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										Close
									</Button>
								</div>
							</span>
						</Modal.Footer>
					) : (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								{currentUserData?._id && (
									<>
										<Button
											disabled={lockReplySubmit}
											onClick={() => {
												const type = "levelComment";
												const force =
													targetThread?.subscribers?.includes(
														currentUserData?._id
													)
														? "unsubscribe"
														: "subscribe";
												setLockReplySubmit(true);
												// backend request
												subscribeToThread(
													targetThread?._id,
													force,
													type
												)
													.then(() => {
														setLockReplySubmit(
															false
														);
														setLevelComments(
															(prevComments) =>
																prevComments.map(
																	(
																		prevComment
																	) => {
																		if (
																			targetThread._id ===
																			prevComment._id
																		) {
																			// Add or remove currentUserData._id based on the value of "force"
																			const updatedSubscribers =
																				force ===
																				"subscribe"
																					? [
																							...(prevComment?.subscribers ||
																								[]),
																							currentUserData._id,
																					  ]
																					: prevComment?.subscribers?.filter(
																							(
																								id
																							) =>
																								id !==
																								currentUserData._id
																					  );
																			return {
																				...(prevComment ||
																					[]),
																				subscribers:
																					updatedSubscribers,
																			};
																		} else {
																			return prevComment;
																		}
																	}
																)
														);
													})
													.catch(() =>
														setLockReplySubmit(
															false
														)
													);
											}}
											style={{
												position: "relative",
												left: "0",
											}}>
											{targetThread?.subscribers?.includes(
												currentUserData?._id
											)
												? "Unsubscribe"
												: "Subscribe"}
										</Button>{" "}
									</>
								)}
								<Button
									onClick={() =>
										setCommentThreadModalShow(false)
									}
									style={{ position: "relative", left: "0" }}>
									Close
								</Button>
							</span>
						</Modal.Footer>
					)}
				</div>
			</Modal>
		);
	};

	const deleteLevelCommentReplyModal = () => {
		return (
			<Modal
				show={deleteCommentReplyModalShow}
				onHide={() => setDeleteCommentReplyModalShow(false)}
				size="sm"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Body>
					<h4 style={{ textAlign: "center" }}>
						{replyDeleteAction === "delete"
							? "Are you sure you want to delete this reply?"
							: replyDeleteAction === "obliterate" &&
							  "Are you sure you want to OBLITERATE this reply from the database?"}
					</h4>
				</Modal.Body>
				<Modal.Footer className="mx-auto">
					<Button
						onClick={() => {
							// level ID
							const levelID = level._id;
							const levelCommentID = targetThread._id;
							const replyID = targetReply._id;

							// make backend call for deleting a reply
							if (replyDeleteAction === "delete") {
								toast.info("deleting reply...");
								deleteLevelCommentReply(
									replyID,
									levelCommentID,
									levelID
								).then(() => {
									setLevelCommentsChanged(true);
								});
							}
							// obliterate it instead
							if (replyDeleteAction === "obliterate") {
								toast.info("obliterating reply...");
								obliterateLevelCommentReply(
									replyID,
									levelCommentID,
									levelID
								).then(() => {
									setLevelCommentsChanged(true);
								});
							}

							// reset values
							setTargetThread("");
							setDeleteCommentReplyModalShow(false);
						}}>
						Yes
					</Button>
					<Button
						onClick={() => setDeleteCommentReplyModalShow(false)}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	const incrementReplyCount = (commentId) => {
		setLevelComments((prevComments) => {
			return prevComments.map((comment) => {
				if (comment._id === commentId) {
					let updatedReplyCount;
					if (!comment.replyCount) {
						updatedReplyCount = (comment?.replies?.length || 0) + 1;
					} else {
						updatedReplyCount = (comment.replyCount || 0) + 1;
					}
					return {
						...comment,
						replyCount: updatedReplyCount,
					};
				}
				return comment;
			});
		});
	};

	return (
		<>
			{levelComments?.map((levelComment, key) => {
				const commentAuthor = levelComment?.author;
				const authorIsAdmin =
					commentAuthor?.mainRoles?.includes(ADMIN_MAIN_ROLE);

				return levelComment?.visibility !== "deleted" ||
					currentUserData?.isStaff ? (
					<div
						key={levelComment?._id}
						className="col-12 col-lg-9 cardsize">
						{!levelComment?.editState ? (
							<div
								className={`card ${
									levelComment?.visibility === "deleted" &&
									"deleted-levelcomment"
								}`}>
								<div
									className={`card-header ${
										levelComment?.highlighted === true &&
										"highlight-card-header"
									}`}
									style={{
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
									}}>
									<div
										style={{
											float: "left",
											height: "38px",
										}}>
										<span
											style={{
												borderRadius: "50%",
												borderWidth: "3px",
												borderStyle: "solid",
												height: "44px",
												width: "44px",
												overflow: "hidden",
												display: "inline-block",
												position: "absolute",
												top: "5px",
												left: "11px",
												borderColor:
													commentAuthor?.icon_outline,
												boxShadow:
													commentAuthor?.icon_glow
														? `0 0 4px 2px ${commentAuthor?.icon_glow}`
														: "none",
											}}>
											<img
												src={
													commentAuthor?.avatar
														? commentAuthor?.avatar
														: defaultAvatar
												}
												onError={(e) => {
													// @ts-ignore
													e.target.src =
														defaultAvatar;
												}}
												alt="Author Avatar"
												className="user-avatar-display"
											/>
										</span>
										<span
											className="align-middle"
											style={{
												position: "relative",
												marginLeft: "48px",
												bottom: "-6px",
											}}>
											<a
												title={commentAuthor?.username}
												href={`/users/${commentAuthor?._id}`}
												onClick={(e) => {
													e.preventDefault();
													history.push(
														`/users/${commentAuthor?._id}`
													);
												}}>
												{commentAuthor?.username}
											</a>
											&nbsp;&nbsp;&nbsp;
											{commentAuthor?.subRoles?.includes(
												REVIEWER_SUBROLE
											) &&
												levelComment?.highlighted ===
													true && (
													<span
														style={{
															border: "2px solid white",
															padding:
																"1px 6px 2px 6px",
															borderRadius:
																"12px",
															fontSize: "11px",
														}}>
														Reviewer
													</span>
												)}
											{levelComment?.betaReview ===
												true && (
												<span
													style={{
														border: "2px solid white",
														padding:
															"1px 6px 2px 6px",
														borderRadius: "12px",
														fontSize: "11px",
													}}>
													Beta comment
												</span>
											)}
										</span>
									</div>
									{/* action buttons for comments */}
									<span
										className="align-middle"
										style={{
											float: "right",
											marginTop: "8px",
										}}
										id="levelButton">
										{/* thread button with amount of replies*/}
										<OverlayTrigger
											delay={{ show: 210, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="replies">
													Replies
												</Tooltip>
											}>
											<span
												style={{
													position: "relative",
													top: "-6px",
													right: "4px",
													fontSize: "18.5px",
													color: "aliceblue",
												}}>
												{/* replycount for comments */}
												{levelComment.replyCount
													? levelComment.replyCount -
													  (levelComment
															?.deleted_replies
															?.length || 0)
													: levelComment?.replies
															?.length -
															(levelComment
																?.deleted_replies
																?.length ||
																0) || 0}
											</span>
										</OverlayTrigger>
										{/* button to open threads */}
										<OverlayTrigger
											delay={{ show: 210, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="openThread">
													Open thread
												</Tooltip>
											}>
											<a
												onClick={() => {
													setCommentThreadModalShow(
														true
													);
													setTargetThread(
														levelComment
													);
													setRepliesThreadChanged(
														true
													);
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue">
												comment
											</a>
										</OverlayTrigger>
										{
											// edit button
											((userIsStaff && !authorIsAdmin) ||
												currentUserData?._id ===
													commentAuthor?._id ||
												userIsAdmin) &&
												levelComment?.visibility !==
													"deleted" && (
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="editComment">
																Edit comment
															</Tooltip>
														}>
														<a
															onClick={() => {
																switchEditState(
																	levelComment?._id,
																	setLevelComments
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue">
															edit
														</a>
													</OverlayTrigger>
												)
										}
										{
											// delete buttons
											(userIsStaff && !authorIsAdmin) ||
											commentAuthor?._id ===
												currentUserData?._id ||
											userIsAdmin ? (
												// delete reply if public or private
												levelComment.visibility !==
												"deleted" ? (
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="deleteComment">
																Delete comment
															</Tooltip>
														}>
														<a
															onClick={() => {
																setLevelCommentToDelete(
																	levelComment
																);
																setDeleteCommentModalShow(
																	true
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon">
															delete
														</a>
													</OverlayTrigger>
												) : (
													// OBLITERATE reply if admin
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="obliterate">
																OBLITERATE
															</Tooltip>
														}>
														<a
															onClick={() => {
																setLevelCommentToDelete(
																	levelComment
																);
																setDeleteCommentModalShow(
																	true
																);
															}}
															href={"#!"}
															className={`material-symbols-outlined obliterate-icon ${
																!userIsAdmin &&
																"hidden"
															}`}>
															local_fire_department
														</a>
													</OverlayTrigger>
												)
											) : (
												""
											)
										}
									</span>
								</div>

								<div
									className={`card-body ${
										levelComment?.highlighted === true &&
										"highlight-card-body"
									}
							`}>
									<div className="center-textoutput">
										<ReadMore
											content={levelComment?.content}
											document={levelComment}
											height={410}
										/>
									</div>
									<hr />

									<div className="comment-footer">
										<CommentStatus
											levelComments={levelComments}
											setLevelComments={setLevelComments}
											level={level}
											levelComment={levelComment}
										/>

										<span className="comment-footer__date">
											Posted on{" "}
											<b>
												{moment(
													levelComment?.postDate
												).format(
													`MMM D, YYYY - h:mm A`
												)}
											</b>
										</span>
									</div>
								</div>
							</div>
						) : (
							<div>
								<div style={{ marginBottom: "16px" }}>
									<span className="save-edit-comment">
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="cancel">
													Cancel
												</Tooltip>
											}>
											<a
												onClick={() => {
													switchEditState(
														levelComment?._id,
														setLevelComments
													);
													toast.warn(
														"Any changes made to this comment will be discarded upon (comment) page reload."
													);
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingLeft: "2px",
												}}>
												close
											</a>
										</OverlayTrigger>
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="saveChanges">
													Save changes
												</Tooltip>
											}>
											<a
												onClick={() => {
													if (
														levelComment?.content !==
														controlLevelComments[
															key
														]?.content
													) {
														if (
															levelComment.content
														) {
															// switch back to normal state
															switchEditState(
																levelComment?._id,
																setLevelComments
															);
															// edit the levelcomment
															editLevelComment(
																level?._id,
																levelComment?._id,
																{
																	content:
																		levelComment?.content,
																}
															).then(() => {
																// make sure the edit state remains false while adding the "edited" tag
																const updatedComments =
																	levelComments.map(
																		(
																			comment,
																			index
																		) => {
																			if (
																				index ===
																				key
																			) {
																				return {
																					...comment,
																					edited: true,
																					editState:
																						false,
																				};
																			}
																			return comment;
																		}
																	);
																setLevelComments(
																	updatedComments
																);
																// update state to account for new changes
																setControlLevelComments(
																	(
																		prevState
																	) => {
																		return {
																			...prevState,
																			[key]: levelComment,
																		};
																	}
																);
															});
														} else {
															toast.error(
																"Comment can't be empty"
															);
														}
													} else {
														toast.error(
															"No changes were made"
														);
													}
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingRight: "3px",
												}}>
												save
											</a>
										</OverlayTrigger>
									</span>
									<textarea
										// @ts-ignore
										type="text"
										className="form form-control edit-level-comment"
										value={levelComment?.content}
										onChange={(e) => {
											const updatedComments =
												levelComments.map(
													(comment, index) => {
														if (index === key) {
															return {
																...comment,
																content:
																	e.target
																		.value,
															};
														}
														return comment;
													}
												);
											setLevelComments(updatedComments);
										}}
									/>
								</div>
							</div>
						)}
						<br />
					</div>
				) : null;
			})}
			{deleteLevelCommentModal()}
			{commentThreadModal()}
			{deleteLevelCommentReplyModal()}
		</>
	);
};

const CommentReplies = (props) => {
	const history = useHistory();
	const currentUserData = useContext<any>(UserContext);
	const userIsStaff = props.userIsStaff;

	return (
		<>
			{props.threadReplies?.map((threadReply, key) => {
				// define reply author
				const replyAuthor = threadReply?.author;

				// define admin comment authors for perms
				const authorIsAdmin =
					replyAuthor?.mainRoles?.includes(ADMIN_MAIN_ROLE);

				return (
					<div key={threadReply?._id}>
						{!threadReply?.editState ? (
							<div
								style={{
									marginBottom: "14px",
									opacity: props.showReplies ? 1 : 0,
								}}
								className={`comment hover-colour thread-fade ${
									threadReply.deleted
										? "deleted-comment"
										: "public-comment"
								} 
										${props.showReplies ? "fade-in-animation" : ""}`}>
								<div>
									<div
										style={{
											position: "absolute",
											top: "6px",
											right: "6px",
										}}>
										{/* display all icons */}
										{
											// edit button
											((userIsStaff && !authorIsAdmin) ||
												currentUserData?._id ===
													replyAuthor?._id ||
												props.userIsAdmin) &&
												threadReply?.deleted !==
													true && (
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="editComment">
																Edit comment
															</Tooltip>
														}>
														<a
															onClick={() => {
																switchEditState(
																	threadReply?._id,
																	props.setThreadReplies
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue">
															edit
														</a>
													</OverlayTrigger>
												)
										}
										{
											// delete buttons
											(userIsStaff && !authorIsAdmin) ||
											replyAuthor?._id ===
												currentUserData?._id ||
											props.userIsAdmin ? (
												// delete reply if public or private
												threadReply.deleted ===
												false ? (
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="deleteReply">
																Delete reply
															</Tooltip>
														}>
														<a
															onClick={() => {
																props.setCommentThreadModalShow(
																	false
																);
																props.setTargetReply(
																	threadReply
																);
																props.setReplyDeleteAction(
																	"delete"
																);
																props.setDeleteCommentReplyModalShow(
																	true
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue">
															delete
														</a>
													</OverlayTrigger>
												) : (
													// OBLITERATE reply if admin
													<OverlayTrigger
														delay={{
															show: 210,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="obliterate">
																OBLITERATE
															</Tooltip>
														}>
														<a
															onClick={() => {
																props.setCommentThreadModalShow(
																	false
																);

																props.setTargetReply(
																	threadReply
																);
																props.setReplyDeleteAction(
																	"obliterate"
																);
																props.setDeleteCommentReplyModalShow(
																	true
																);
															}}
															href={"#!"}
															className={`material-symbols-outlined thread-icon ${
																!props.userIsAdmin &&
																"hidden"
															}`}>
															local_fire_department
														</a>
													</OverlayTrigger>
												)
											) : null
										}
									</div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											borderColor:
												replyAuthor?.icon_outline,
											boxShadow: replyAuthor?.icon_glow
												? `0 0 4px 2px ${replyAuthor?.icon_glow}`
												: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													props.images?.defaultAvatar;
											}}
											src={
												replyAuthor?.avatar
													? replyAuthor?.avatar
													: props.images
															?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={replyAuthor?.username}
											href={`/users/${replyAuthor?._id}`}
											onClick={(e) => {
												e.preventDefault();
												history.push(
													`/users/${replyAuthor?._id}`
												);
											}}>
											{replyAuthor?.username}
										</a>
									</span>
									<hr
										style={{
											margin: "4px 7px 20px 7px",
										}}
									/>
								</div>
								{/* comment + timestamp */}
								<div className="comment__comment-body">
									<ReadMore
										content={threadReply?.content}
										document={threadReply}
										height={410}
									/>
								</div>
								<div className="comment__comment-timestamp">
									Posted on{" "}
									<b>
										{moment(threadReply?.postDate).format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
							</div>
						) : (
							<div>
								<div style={{ marginBottom: "16px" }}>
									<span className="save-edit-comment">
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="cancel">
													Cancel
												</Tooltip>
											}>
											<a
												onClick={() => {
													switchEditState(
														threadReply?._id,
														props.setThreadReplies
													);
													toast.warn(
														"Any changes made to this comment will be discarded upon (comment) page reload."
													);
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingLeft: "2px",
												}}>
												close
											</a>
										</OverlayTrigger>
										<OverlayTrigger
											delay={{ show: 130, hide: 0 }}
											placement="top"
											overlay={
												<Tooltip id="saveChanges">
													Save changes
												</Tooltip>
											}>
											<a
												onClick={() => {
													if (
														threadReply?.content !==
														props
															.controlThreadReplies[
															key
														]?.content
													) {
														if (
															threadReply.content
														) {
															// switch the edit state back to normal
															switchEditState(
																threadReply?._id,
																props.setThreadReplies
															);
															// edit the comment
															editLevelCommentReply(
																props.level
																	?._id,
																threadReply?._id,
																{
																	content:
																		threadReply?.content,
																}
															).then(() => {
																// make sure the edit state remains false, add the "edited" tag
																const updatedReplies =
																	props.threadReplies.map(
																		(
																			reply,
																			index
																		) => {
																			if (
																				index ===
																				key
																			) {
																				return {
																					...threadReply,
																					edited: true,
																					editState:
																						false,
																				};
																			}
																			return reply;
																		}
																	);
																props.setThreadReplies(
																	updatedReplies
																);
																// update state to account for new changes
																props.setControlThreadReplies(
																	(
																		prevState
																	) => {
																		return {
																			...prevState,
																			[key]: threadReply[
																				key
																			],
																		};
																	}
																);
															});
														} else {
															toast.error(
																"Comment can't be empty"
															);
														}
													} else {
														toast.error(
															"No changes were made"
														);
													}
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue"
												style={{
													paddingTop: "3.5px",
													paddingRight: "3px",
												}}>
												save
											</a>
										</OverlayTrigger>
									</span>
									<textarea
										// @ts-ignore
										type="text"
										className="form form-control edit-level-comment"
										value={threadReply?.content}
										onChange={(e) => {
											const updatedComments = [
												...props.threadReplies,
											];
											updatedComments[key].content =
												e.target.value;
											props.setThreadReplies(
												updatedComments
											);
										}}></textarea>
								</div>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
};

const CommentStatus = ({
	levelComments,
	setLevelComments,
	level,
	levelComment,
}) => {
	const targetLevelComment = levelComments.find(
		(comment) => comment?._id === levelComment._id
	);

	const currentUserData = useContext<any>(UserContext);
	const userIsMuted = currentUserData?.mainRoles?.includes(MUTED_USER);
	const userIsRateRestricted =
		currentUserData?.mainRoles.includes(RATE_RESTRICTED_USER);

	const isDisliked = targetLevelComment?.user_dislikes?.includes(
		currentUserData?._id
	);
	const isLiked = targetLevelComment?.user_likes?.includes(
		currentUserData?._id
	);

	return (
		<>
			<div className="comment-footer__react">
				<button
					className={`comment-footer__react__button${
						isLiked ? "__active-up" : ""
					}`}
					onClick={() => {
						if (
							targetLevelComment &&
							(!currentUserData?._id ||
								userIsMuted ||
								userIsRateRestricted)
						)
							return toast.error(
								`${
									!currentUserData?._id
										? "You must login to do that."
										: userIsMuted
										? "You can't like/dislike comments while muted."
										: userIsRateRestricted
										? "You can't like/dislike comments while rate restricted."
										: "Please contact an admin if you see this error."
								}`
							);
						handleReactComment({
							mode: "like",
							comment: targetLevelComment,
							origin: level,
							currentUserData,
							reactComment: reactLevelComment,
							setComments: setLevelComments,
						});
					}}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isLiked
									? `material-icons`
									: `material-symbols-outlined`
							}>
							thumb_up
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{targetLevelComment?.user_likes?.length || 0}
					</span>
				</button>

				<button
					className={`comment-footer__react__button${
						isDisliked ? "__active-down" : ""
					}`}
					onClick={() => {
						if (
							targetLevelComment &&
							(!currentUserData?._id ||
								userIsMuted ||
								userIsRateRestricted)
						)
							return toast.error(
								`${
									!currentUserData?._id
										? "You must login to do that."
										: userIsMuted
										? "You can't like/dislike comments while muted."
										: userIsRateRestricted
										? "You can't like/dislike comments while rate restricted."
										: "Please contact an admin if you see this error."
								}`
							);
						handleReactComment({
							mode: "dislike",
							comment: targetLevelComment,
							origin: level,
							currentUserData,
							reactComment: reactLevelComment,
							setComments: setLevelComments,
						});
					}}>
					<span className={`comment-footer__react__button__symbol`}>
						<span
							className={
								isDisliked
									? `material-icons`
									: `material-symbols-outlined`
							}>
							thumb_down
						</span>
					</span>
					<span className="comment-footer__react__button__number">
						{targetLevelComment?.user_dislikes?.length || 0}
					</span>
				</button>
			</div>
		</>
	);
};

const MappedRates = ({
	index,
	rate,
	content,
	authors,
	levelID,
	setLevel,
	setRatesToMap,
}) => {
	const currentUserData = useContext<any>(UserContext);
	const [deleteState, setDeleteState] = useState<boolean>(false);
	const [deleted, setDeleted] = useState<boolean>(false);
	const canSeeUsers = currentUserData?.isStaff;

	const history = useHistory();
	const author = authors?.find((author) => author?.index === index);
	const originalRate = parseInt(content?.rates[index]);

	const handleClick = (e) => {
		e.preventDefault();
		// return error if rate is deleted.
		if (deleted) return toast.error("This rate has already been deleted!");

		if (!deleteState) {
			// set delete state to true
			setDeleteState(true);
			// reset after 3 seconds
			setTimeout(() => setDeleteState(false), 3000);
			return toast.warn("Click again to confirm permanent removal.");
		} else {
			// perform the backend call if delete state is true
			removeLevelRate(levelID, { user: author?._id, index })
				.then((response) => {
					// update the state of the level
					const data = response?.data;
					setDeleteState(false);
					setLevel((prevState) => {
						return {
							...prevState,
							rating: data.rating,
							rates: prevState.rates.filter(
								(_, key) => key !== index
							),
						};
					});
					setRatesToMap((prevState) => {
						const newState = prevState.filter(
							(_, key) => key !== index
						);
						return newState;
					});

					// set to initial state
				})
				.catch((error) => {
					setDeleteState(false);
					if (error?.response?.status === 403) setDeleted(true);
				});
		}
	};

	return (
		<>
			{window.innerWidth > 334 && (
				<div className={canSeeUsers ? "col-1" : "col-3"}>
					<div
						className="vote-logs-text"
						style={{
							color: deleteState || deleted ? "red" : "",
							transition: "color 0.3s ease-in-out",
							whiteSpace: "nowrap",
						}}>
						#{index + 1}
					</div>
				</div>
			)}
			<div className={canSeeUsers ? "col-5" : "col-9"}>
				<MapStars
					level={null}
					rate={rate}
					width="10mm"
					height="16mm"
					top={undefined}
					color={
						deleteState || deleted
							? "#c70700"
							: originalRate === 5
							? "#2cd40f"
							: ""
					}
				/>
			</div>
			{canSeeUsers ? (
				<>
					<div className="col-5">
						<a
							href={`/users/${author?._id}`}
							style={{
								color: deleteState || deleted ? "red" : "",
								transition: "color 0.3s ease-in-out",
							}}
							onClick={(e) => {
								e.preventDefault();
								history.push(`/users/${author?._id}`);
							}}
							className="vote-logs-text">
							{author?.username}
						</a>
					</div>
					<div className="col-1">
						<div className="vote-logs-text">
							<OverlayTrigger
								overlay={
									<Tooltip id="removeRate">
										{deleted
											? "This rate has already been deleted!"
											: deleteState
											? "Confirm removal"
											: "Remove star rate"}
									</Tooltip>
								}>
								<a
									href="#!"
									className="material-icons thread-icon"
									style={{
										color:
											deleteState || deleted ? "red" : "",
										transition: "color 0.3s ease-in-out",
									}}
									onClick={(e) => handleClick(e)}>
									delete
								</a>
							</OverlayTrigger>
						</div>
					</div>
				</>
			) : null}
			<hr
				style={{
					marginBottom: "0px",
					marginTop: "4px",
				}}
			/>
		</>
	);
};

export default Level;
