import React from "react";

// constants
import {
	ADMIN_MAIN_ROLE,
	ARCHIVE_ACCOUNT,
	BANNED_USER,
	BETA_TESTER_MAIN_ROLE,
	DEACTIVATED_ACCOUNT,
	DEVELOPER_MAIN_ROLE,
	DONATOR_MAIN_ROLE,
	DONATOR_PLUS_MAIN_ROLE,
	ELDER_GOD_MAIN_ROLE,
	LEADER_MAIN_ROLE,
	MEMBER_MAIN_ROLE,
	MITTEN_MAIN_ROLE,
	MODERATOR_MAIN_ROLE,
	MUTED_USER,
	PARTNER_MAIN_ROLE,
	RATE_RESTRICTED_USER,
	USER_WARNING,
} from "../constants";

// map the highest role and/or apply a color based upon it
export const mapHighestRole = (userRoles, mode) => {
	const excludedRoles = [MUTED_USER, RATE_RESTRICTED_USER];
	const rolesRankMap = {
		[LEADER_MAIN_ROLE]: 9,
		[ADMIN_MAIN_ROLE]: 8,
		[DEVELOPER_MAIN_ROLE]: 7,
		[PARTNER_MAIN_ROLE]: 6,
		[MODERATOR_MAIN_ROLE]: 5,
		[MITTEN_MAIN_ROLE]: 4,
		[DONATOR_PLUS_MAIN_ROLE]: 3,
		[DONATOR_MAIN_ROLE]: 2,
		[BETA_TESTER_MAIN_ROLE]: 1,
		[BANNED_USER]: -1,
		[MUTED_USER]: -2,
		[RATE_RESTRICTED_USER]: -3,
		[DEACTIVATED_ACCOUNT]: -4,
		[ARCHIVE_ACCOUNT]: -5,
	};

	const roleColors = {
		[LEADER_MAIN_ROLE]: "#ff1919",
		[ADMIN_MAIN_ROLE]: "#1cde02",
		[DEVELOPER_MAIN_ROLE]: "#f0e630",
		[PARTNER_MAIN_ROLE]: "#c93ff3",
		[MODERATOR_MAIN_ROLE]: "#ffa500",
		[MITTEN_MAIN_ROLE]: "#fac125",
		[DONATOR_PLUS_MAIN_ROLE]: "#31f7f1",
		[DONATOR_MAIN_ROLE]: "#31f7f1",
		[BETA_TESTER_MAIN_ROLE]: "#ffb3ff",
		[BANNED_USER]: "#9c9c9c",
	};

	const cardColors = {
		[LEADER_MAIN_ROLE]: "#ff191950",
		[ADMIN_MAIN_ROLE]: "#1cde0224",
		[DEVELOPER_MAIN_ROLE]: "#f0e63025",
		[PARTNER_MAIN_ROLE]: "#c93ff340",
		[MODERATOR_MAIN_ROLE]: "#ffa50040",
		[MITTEN_MAIN_ROLE]: "#fac12530",
		[DONATOR_PLUS_MAIN_ROLE]: "#31f7f140",
		[DONATOR_MAIN_ROLE]: "#31f7f140",
		[BETA_TESTER_MAIN_ROLE]: "#ffb3ff40",
		[BANNED_USER]: "#31f7f140",
	};

	if (userRoles && userRoles.length > 0) {
		const filteredRoles = userRoles?.filter(
			(role) => !excludedRoles.includes(role)
		);
		const filteredExistingRoles = filteredRoles?.filter((role) =>
			rolesRankMap?.hasOwnProperty(role)
		);

		if (filteredExistingRoles.length > 0) {
			const highestRole = filteredRoles.reduce((highest, role) => {
				return rolesRankMap[role] > rolesRankMap[highest]
					? role
					: highest;
			});

			if (mode === "color") return roleColors[highestRole]; // Return the color
			if (mode === "card") return cardColors[highestRole]; // Return the color
			if (mode === "role") return highestRole; // Return the role
		}
	}

	// darius.
	if (userRoles?.includes(ELDER_GOD_MAIN_ROLE) && mode === "role")
		return "Elder God";

	return mode === "color"
		? "#ffffff"
		: mode === "role"
		? MEMBER_MAIN_ROLE
		: mode === "card" && "rgba(0,0,0,0.4)"; // Return a default color or role if no roles found
};

// sorts an array of roles
export const sortRoles = (userRoles) => {
	const rolePriority = {
		[LEADER_MAIN_ROLE]: 1,
		[ADMIN_MAIN_ROLE]: 2,
		[DEVELOPER_MAIN_ROLE]: 3,
		[PARTNER_MAIN_ROLE]: 4,
		[MODERATOR_MAIN_ROLE]: 5,
		[MITTEN_MAIN_ROLE]: 6,
		[DONATOR_PLUS_MAIN_ROLE]: 7,
		[DONATOR_MAIN_ROLE]: 8,
		[BETA_TESTER_MAIN_ROLE]: 9,
		[BANNED_USER]: 10,
		[MUTED_USER]: 11,
		[RATE_RESTRICTED_USER]: 12,
	};
	const sortedRoles = userRoles?.sort(
		(a, b) => rolePriority[a] - rolePriority[b]
	);
	return sortedRoles;
};

// displayrole styles with fade-in animation
//! pass prop in the same manner as done on profile/hoverusercard component
export const displayRoleStyle = (props) => {
	let opacity;

	// use transition style
	if (props?.showContent === true || props?.showContent === false)
		opacity = { opacity: props.showContent ? "1" : "0" };

	// use loop value
	if (props?.loadValue !== undefined)
		opacity = { opacity: (props.loadValue - 50) / 50 };

	// return the styles
	return {
		...opacity,
		transition: "opacity 0.6s ease-in-out",
		paddingLeft: "6px",
		paddingRight: "6px",
		marginRight: "6px",
		position: "relative",
	};
};

export const punishmentColor = (punishments) => {
	const infractions = [
		MUTED_USER,
		RATE_RESTRICTED_USER,
		BANNED_USER,
		USER_WARNING,
	];
	// return if no punishments match up
	if (punishments.some((punishment) => !infractions.includes(punishment)))
		return <span>- Error -</span>;
	// mute & rate restrict
	if (punishments?.length > 1)
		return (
			<>
				<span className="gray">Muted</span> &{" "}
				<span className="gloamvitiation-orange">Rate Restricted</span>
			</>
		);
	// muted
	if (punishments?.includes(MUTED_USER))
		return <span className="gray">{punishments[0]}</span>;
	// banned
	if (punishments?.includes(BANNED_USER))
		return <span className="hydrawisp-red">{punishments[0]}</span>;
	// rate restricted
	if (punishments?.includes(RATE_RESTRICTED_USER))
		return <span className="gloamvitiation-orange">{punishments[0]}</span>;
	// warned
	if (punishments?.includes(USER_WARNING))
		return <span className="yellow">{punishments[0]}</span>;
};
